import { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Template from "../../template/Template";
import TableDataSimple from '../../components/Tables/TableDataSimple';
import SimpleBackdrop from "../../components/Loading/SimpleBackdrop";
import { AlertInfo } from "../../components/Alerts/Alert";
import { api } from "../../services/api";
import { displayId, displayMoney } from '../../services/displayValue';

import ModalCadastroProduto from "./ModalCadastroProduto";
import ModalPrecoProduto from "./ModalPrecoProduto";
import isPermission from "../../services/permission";

export default function Produtos() {
    const [openLoad, setOpenLoad] = useState(false);
    const [openModalPrecoProduto, setOpenModalPrecoProduto] = useState(false);
    const [openModalCadastro, setOpenModalCadastro] = useState(false);
    const [infoCadastro, setInfoCadastro] = useState(null);
    const [rows, setRows] = useState([]);
    const columns = [
        {
            id: 'id_produto',
            numeric: false,
            label: 'CODIGO',
            format: displayId
        },
        {
            id: 'cod_serpro',
            numeric: false,
            label: 'SERPRO',
        },
        {
            id: 'descricao',
            numeric: false,
            label: 'DESCIÇÃO',
        },
        {
            id: 'preco1',
            numeric: true,
            label: 'VALOR',
            format: displayMoney
        },
    ];

    function handleClick(row) {
        if(isPermission(12)){
            setInfoCadastro(row);
            setOpenModalPrecoProduto(true);
        }
    }

    async function loadData() {
        setOpenLoad(true);
        try {
            const response = await api.get(`/produtos`);
            if (response && response.status === 200 && response.data) {
                if (response.data.error) AlertInfo("error", response.data.message);
                else setRows(response.data);
            }
        } catch (error) {
            console.log(error);
            AlertInfo("error", 'NÃO POSSIVEL BUSCAR DADOS');
        }
        setOpenLoad(false);
    }

    useEffect(() => {
        loadData();
        // eslint-disable-next-line
    }, []);

    return (
        <Template menuActive="produtos">
            <SimpleBackdrop open={openLoad} />
            <ModalCadastroProduto
                open={openModalCadastro}
                setOpen={setOpenModalCadastro}
                info={infoCadastro}
                setInfo={setInfoCadastro}
                loadData={loadData}
            />
            <ModalPrecoProduto
                open={openModalPrecoProduto}
                setOpen={setOpenModalPrecoProduto}
                info={infoCadastro}
                setInfo={setInfoCadastro}
                loadData={loadData}
            />

            <Grid container spacing={1}>
                {isPermission(11) && (
                    <Grid item xs={12} sm={2}>
                        <Button
                            variant="contained"
                            fullWidth
                            onClick={() => setOpenModalCadastro(true)}
                        >
                            NOVO
                        </Button>
                    </Grid>
                )}
                <Grid item xs={12}>
                    <h3>Lista de Produtos</h3>
                </Grid>
                <Grid item xs={12}>
                    <TableDataSimple
                        columns={columns}
                        rows={rows}
                        handleClick={handleClick}
                    />
                </Grid>
            </Grid>
        </Template>
    );
}