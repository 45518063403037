import { useState } from "react";
import moment from "moment";
import { ListItem } from "@mui/material";
import Button from '@mui/material/Button';
import DrawerApp from "../../components/Drawer/DrawerApp";
import SelectSimple from '../../components/Selects/SelectSimple';
import Input from '../../components/Inputs/Input';
import { startMes, endMes } from "../../services/date";
import { apiUrl } from '../../services/api';
import isPermission from "../../services/permission";

export default function DrawerRelatorio({ open, setOpen }) {
    const [infoLogin] = useState(JSON.parse(sessionStorage.getItem('infoLogin')));
    const [is_geral, setIsGeral] = useState("1");
    const [tiporel, setTipoRel] = useState("relatoriovendas");

    const [busca, setBusca] = useState("1");
    const [mes, setMes] = useState(moment().format('YYYY-MM'));
    const [dataInicio, setDataInicio] = useState("");
    const [dataFinal, setDataFinal] = useState("");

    function handleSearch() {
        const start = busca === "1" ? startMes(mes) : dataInicio;
        const end = busca === "1" ? endMes(mes) : dataFinal;
        const column = tiporel === 'relatoriorenovacao' ? 'validade_fim' : 'data_pedido';
        const query = `column=${column}&start=${start}&end=${end}&id_empresa_certificado=${infoLogin.id_empresa_usuario}`;
        if(is_geral === "1") window.open(`${apiUrl}/relatoriocertificados/${tiporel}?${query}`);
        else if(is_geral === "2") window.open(`${apiUrl}/relatoriogeralcertificados?${query}`);
    }

    return (
        <DrawerApp
            open={open}
            setOpen={setOpen}
            title="RELATORIOS"
        >
            {isPermission(21) && (
                <ListItem>
                    <SelectSimple
                        label="CONTEUDO RELATORIO"
                        value={is_geral}
                        setValue={setIsGeral}
                        options={[
                            { value: "1", label: 'NORMAL' },
                            { value: "2", label: 'GERAL' },
                        ]}
                    />
                </ListItem>
            )}
            {is_geral === "1" && (
                <ListItem>
                    <SelectSimple
                        label="TIPO RELATORIO"
                        value={tiporel}
                        setValue={setTipoRel}
                        options={[
                            { value: "relatoriovendas", label: 'VENDAS' },
                            { value: "relatoriocustos", label: 'CUSTOS' },
                            { value: "relatoriorenovacao", label: 'RENOVAÇÃO' },
                        ]}
                    />
                </ListItem>
            )}
            <ListItem>
                <SelectSimple
                    label="BUSCA"
                    value={busca}
                    setValue={setBusca}
                    options={[
                        { value: "1", label: 'MÊS' },
                        { value: "2", label: 'PERIODO' },
                    ]}
                />
            </ListItem>

            {busca === "1" && (
                <ListItem>
                    <Input
                        type="month"
                        label="MÊS"
                        value={mes}
                        upper={false}
                        setValue={setMes}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </ListItem>
            )}
            {busca === "2" && (
                <>
                    <ListItem>
                        <Input
                            type="date"
                            label="DATA INCIO"
                            value={dataInicio}
                            setValue={setDataInicio}
                            upper={false}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </ListItem>
                    <ListItem>
                        <Input
                            type="date"
                            label="DATA FINAL"
                            value={dataFinal}
                            upper={false}
                            setValue={setDataFinal}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </ListItem>
                </>
            )}

            <ListItem>
                <Button
                    variant="contained"
                    fullWidth
                    onClick={() => handleSearch()}
                >
                    RELATORIO
                </Button>
            </ListItem>
        </DrawerApp>
    );
}