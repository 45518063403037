import { useEffect, useState } from "react";
import { Button, Grid } from "@mui/material";
import Modal from "../../components/Modal/Modal";
import SimpleBackdrop from "../../components/Loading/SimpleBackdrop";
import Input from "../../components/Inputs/Input";
import InputDisplay from "../../components/Inputs/InputDisplay";
import { AlertInfo } from "../../components/Alerts/Alert";
import { converterMoeda, displayMoney } from "../../services/displayValue";
import { api } from "../../services/api";

import ModalCadastroProduto from "./ModalCadastroProduto";
import isPermission from "../../services/permission";

export default function ModalPrecoProduto({ open, setOpen, info, setInfo, loadData }) {
    const [infoLogin] = useState(JSON.parse(sessionStorage.getItem('infoLogin')));
    const [id_empresa_produto] = useState(infoLogin.id_empresa_usuario);
    const [openModalCadastro, setOpenModalCadastro] = useState(false);
    const [openLoad, setOpenLoad] = useState(false);
    const [revenda, setRevenda] = useState("");
    const [preco1, setPreco1] = useState("");
    const [preco2, setPreco2] = useState("");
    const [preco3, setPreco3] = useState("");
    const [infoModal, setInfoModal] = useState(null);

    async function handleSave() {
        setOpenLoad(true);
        try {
            const data = {
                id_empresa_produto, revenda: converterMoeda(revenda),
                preco1: converterMoeda(preco1),
                preco2: converterMoeda(preco2),
                preco3: converterMoeda(preco3),
                id_preco_produto: info.id_preco_produto,
            };
            const response = await api.post(`updateprecoproduto`, data);
            if (response && response.status === 200 && response.data) {
                if (response.data.error) {
                    AlertInfo('error', response.data.message);
                } else {
                    loadData();
                    AlertInfo('success', 'DADOS GRAVADOS').then(() => {
                        setOpen(false);
                    });
                }
            } else {
                AlertInfo('error', 'FALHA NA OPERAÇÃO');
            }
        } catch (error) {
            console.log(error);
            AlertInfo('error', 'FALHA NA CONEXÃO');
        }
        setOpenLoad(false);
    }

    function dataModal() {
        if (open && info && info.id_produto) {
            setRevenda(displayMoney(info.revenda));
            setPreco1(displayMoney(info.preco1));
            setPreco2(displayMoney(info.preco2));
            setPreco3(displayMoney(info.preco3));
        } else if (!open) {
            setRevenda("");
            setPreco1("");
            setPreco2("");
            setPreco3("");
            setInfo(null);
        }
    }

    function handleEdit() {
        setInfoModal(info);
        setOpenModalCadastro(true);
    }

    useEffect(() => {
        dataModal();
        // eslint-disable-next-line
    }, [open]);

    return (
        <Modal
            open={open}
            setOpen={setOpen}
            title="PREÇOS PRODUTO"
            maxWidth="sm"
        >
            <SimpleBackdrop open={openLoad} />
            <ModalCadastroProduto
                open={openModalCadastro}
                setOpen={setOpenModalCadastro}
                info={infoModal}
                setInfo={setInfoModal}
                loadData={loadData}
            />

            <Grid container spacing={1}>
                <Grid item xs={false} sm={9}></Grid>
                <Grid item xs={12} sm={3}>
                    {isPermission(11) && (
                        <Button
                            variant="contained"
                            fullWidth
                            onClick={handleEdit}
                        >
                            EDITAR
                        </Button>
                    )}
                </Grid>
                <Grid item xs={12}>
                    <InputDisplay
                        label="DESCRIÇÃO"
                        value={info ? info.descricao : ""}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Input
                        id="custo"
                        label="CUSTO"
                        value={revenda}
                        setValue={setRevenda}
                        next="preco1"
                        disabled={!isPermission(23)}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Input
                        id="preco1"
                        label="PREÇO 1"
                        value={preco1}
                        setValue={setPreco1}
                        next="preco2"
                    />
                </Grid>
                <Grid item xs={12}>
                    <Input
                        id="preco2"
                        label="PREÇO 2"
                        value={preco2}
                        setValue={setPreco2}
                        next="preco3"
                    />
                </Grid>
                <Grid item xs={12}>
                    <Input
                        id="preco3"
                        label="PREÇO 3"
                        value={preco3}
                        setValue={setPreco3}
                    />
                </Grid>
                <Grid item xs={false} sm={3}></Grid>
                <Grid item xs={12} sm={6}>
                    <Button
                        variant="contained"
                        fullWidth
                        onClick={() => handleSave()}
                    >
                        SALVAR
                    </Button>
                </Grid>
                <Grid item xs={false} sm={3}></Grid>
            </Grid>
        </Modal>
    );
}