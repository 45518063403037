import { FaStickyNote } from "react-icons/fa";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import ListItemText from "@mui/material/ListItemText";

export default function PaperList({ title, array, color, columns, emptyText, icon, format, buttonColor = "primary", clickFunc = () => {} }) {
    const IconList = icon ? icon : FaStickyNote;

    return (
        <>
            <Button
                variant="contained"
                color={buttonColor}
                style={{ marginBottom: '-10px', marginLeft: '10px', zIndex: 111 }}
            >
                {title}
            </Button>
            <Paper elevation={3} style={{ height: '55vh', overflowY: 'auto' }}>
                <List component="nav">
                    {array.map((item, index) => {
                        return (
                            <ListItem sx={{ padding: 0 }} key={index} id={item.id_certificado}>
                                <ListItemButton sx={{ paddingTop: 0, paddingBottom: 0 }} onClick={e => clickFunc(item, e)}>
                                    <ListItemAvatar>
                                        <Avatar style={{ backgroundColor: color(item) }}>
                                            <IconList />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={<span>{item[columns.primary]}</span>}
                                        secondary={<span>{item[columns.secondary]} <br /> {format(item)}</span>}
                                    />
                                </ListItemButton>
                            </ListItem>
                        );
                    })}
                    {array.length === 0 && (
                        <ListItem>
                            <ListItemAvatar>
                                <Avatar>
                                    <FaStickyNote />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={<span>{emptyText}</span>}
                            />
                        </ListItem>
                    )}
                </List>
            </Paper>
        </>
    );
}