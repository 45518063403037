import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

export default function SelectData({ label, value, setValue, options, ...rest }) {
    function handleChange(event, newValue){
        setValue(newValue);
    }

    return (
        <Autocomplete
            disablePortal
            fullWidth
            id="autocomplete"
            options={options}
            sx={{ width: 300 }}
            value={value}
            onChange={handleChange}
            renderInput={(params) => <TextField {...params} label={label} id="autocomplete" fullWidth />}
            size='small'
            {...rest}
        />
    );
}