export const arrayTipoPessoa = [
    { value: 'F', label: 'FISICA' },
    { value: 'J', label: 'JURIDICA' },
];

export const formasPagamento = [
    { value: "3", label: 'AGUARDANDO PAGAMENTO' },
    { value: "0", label: 'DINHEIRO' },
    { value: "1", label: 'BOLETO' },
    { value: "6", label: 'PIX DINAMICO' },
    { value: "8", label: 'PIX ESTATICO' },
    { value: "2", label: 'CARTÃO DE CRÉDITO' },
    { value: "4", label: 'CARTÃO DE DÉBITO' },
];

export const statusCertificado = [
    { value: "Pendente", label: 'Pendente' },
    { value: "Pré-Aprovado", label: 'Pré-Aprovado' },
    { value: "Aprovado", label: 'Aprovado' },
    { value: "Certificado Emitido", label: 'Certificado Emitido' },
    { value: "Emitido", label: 'Emitido' },
    { value: "CANCELADO", label: 'CANCELADO' },
];