import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Template from "../../template/Template";
import TableDataSimple from '../../components/Tables/TableDataSimple';
import SimpleBackdrop from "../../components/Loading/SimpleBackdrop";
import { AlertInfo } from "../../components/Alerts/Alert";
import { api } from "../../services/api";
import isPermission from "../../services/permission";

export default function Contabilidades() {
    const [infoLogin] = useState(JSON.parse(sessionStorage.getItem('infoLogin')));
    const [openLoad, setOpenLoad] = useState(false);
    const [rows, setRows] = useState([]);
    const navigate = useNavigate();
    const columns = [
        {
            id: 'id_contador',
            numeric: false,
            label: 'CODIGO',
        },
        {
            id: 'empresa_contador',
            numeric: false,
            disablePadding: false,
            label: 'CONTABILIDADE',
        },
        {
            id: 'cidade_contador',
            numeric: false,
            disablePadding: false,
            label: 'CIDADE',
        },
        {
            id: 'telefone_contador',
            numeric: false,
            disablePadding: false,
            label: 'TELEFONE',
        },
        {
            id: 'email_contador',
            numeric: false,
            disablePadding: false,
            label: 'EMAIL',
        }
    ];

    function handleClick(row) {
        if(Number(row.user_cad_contador) === Number(infoLogin.id_usuario)){
            navigate(`/cadastrocontador?id=${row.id_contador}`);
        }else{
            AlertInfo('info', 'USUARIO SEM PERMISSÃO PARA EDITAR CADASTRO');
        }
    }

    async function loadData() {
        setOpenLoad(true);
        try {
            const response = await api.get(`/contadores`);
            if(response && response.status === 200 && response.data){
                if(response.data.error) AlertInfo("error", response.data.message);
                else setRows(response.data);
            }
        } catch (error) {
            console.log(error);
            AlertInfo("error", 'NÃO POSSIVEL BUSCAR DADOS');
        }
        setOpenLoad(false);
    }

    useEffect(() => {
        loadData();
        // eslint-disable-next-line
    }, []);

    return (
        <Template menuActive="contabilidade">
            <SimpleBackdrop open={openLoad} />
            <Grid container spacing={1}>
                {isPermission(5) && (
                    <Grid item xs={12} sm={2}>
                        <Link to="/cadastrocontador">
                            <Button
                                variant="contained"
                                fullWidth
                            >
                                NOVO
                            </Button>
                        </Link>
                    </Grid>
                )}
                <Grid item xs={12}>
                    <h3>Lista de Contadores</h3>
                </Grid>
                <Grid item xs={12}>
                    <TableDataSimple
                        columns={columns}
                        rows={rows}
                        handleClick={handleClick}
                    />
                </Grid>
            </Grid>
        </Template>
    );
}