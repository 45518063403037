import { useState, useEffect } from "react";
import Grid from '@mui/material/Grid';
import Button from "@mui/material/Button";
import Modal from '../../components/Modal/Modal';
import SimpleBackdrop from "../../components/Loading/SimpleBackdrop";
import Input from "../../components/Inputs/Input";
import { AlertConfirm, AlertInfo } from "../../components/Alerts/Alert";
import { api } from "../../services/api";
import { converterMoeda, displayMoney } from "../../services/displayValue";

export default function ModalCadastroProduto({ open, setOpen, info, setInfo, loadData }) {
    const [openLoad, setOpenLoad] = useState(false);
    const [id_produto, setIdProduto] = useState(null);
    const [descricao, setDescricao] = useState("");
    const [cod_serpro, setCodSerpro] = useState("");
    const [custo, setCusto] = useState("");

    async function handleSave() {
        setOpenLoad(true);
        try {
            const data = {
                descricao, cod_serpro, id_produto,
                custo: converterMoeda(custo),
            };
            const channel = id_produto ? `updateproduto` : `createproduto`;
            const response = await api.post(channel, data);
            if (response && response.status === 200 && response.data) {
                if (response.data.error) {
                    AlertInfo('error', response.data.message);
                } else {
                    loadData();
                    AlertInfo('success', 'DADOS GRAVADOS').then(() => {
                        setOpen(false);
                    });
                }
            } else {
                AlertInfo('error', 'FALHA NA OPERAÇÃO');
            }
        } catch (error) {
            console.log(error);
            AlertInfo('error', 'FALHA NA CONEXÃO');
        }
        setOpenLoad(false);
    }

    async function handleDelete() {
        AlertConfirm('', 'DELETAR PRODUTO?', 'A Ação não poderá ser desfeita').then(async res => {
            if(res.isConfirmed){
                setOpenLoad(true);
                const response = await api.delete(`/deleteproduto/${id_produto}`);
                if(response && response.status === 200 && response.data){
                    if(response.data.success){
                        loadData();
                        setOpen(false);
                        AlertInfo('success','PRODUTO DELETADO');
                    }
                }
                setOpenLoad(false);
            }
        });
    }

    function dataModal() {
        if (open && info && info.id_produto) {
            setIdProduto(info.id_produto);
            setDescricao(info.descricao);
            setCodSerpro(info.cod_serpro);
            setCusto(displayMoney(info.custo));
        } else if (!open) {
            setIdProduto(null);
            setDescricao("");
            setCodSerpro("");
            setCusto("");
            setInfo(null);
        }
    }

    useEffect(() => {
        dataModal();
        // eslint-disable-next-line
    }, [open]);

    return (
        <Modal
            open={open}
            setOpen={setOpen}
            title="CADASTRO PRODUTO"
            maxWidth="sm"
        >
            <SimpleBackdrop open={openLoad} />
            <Grid container spacing={1}>
                <Grid item xs={false} sm={9}></Grid>
                <Grid item xs={12} sm={3}>
                    {id_produto && (
                        <Button
                            variant="contained"
                            fullWidth
                            onClick={() => handleDelete()}
                        >
                            DELETAR
                        </Button>
                    )}
                </Grid>
                <Grid item xs={12}>
                    <Input
                        id="descricao"
                        label="DESCRIÇAO"
                        value={descricao}
                        setValue={setDescricao}
                        next="cod_serpro"
                        autoFocus
                    />
                </Grid>
                <Grid item xs={12}>
                    <Input
                        id="cod_serpro"
                        label="CODIGO SERPRO"
                        value={cod_serpro}
                        setValue={setCodSerpro}
                        next="custo"
                    />
                </Grid>
                <Grid item xs={12}>
                    <Input
                        id="custo"
                        label="CUSTO"
                        value={custo}
                        setValue={setCusto}
                        next="preco1"
                    />
                </Grid>
                <Grid item xs={false} sm={3}></Grid>
                <Grid item xs={12} sm={6}>
                    <Button
                        variant="contained"
                        fullWidth
                        onClick={() => handleSave()}
                    >
                        SALVAR
                    </Button>
                </Grid>
                <Grid item xs={false} sm={3}></Grid>
            </Grid>
        </Modal>
    );
}