import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Template from "../../template/Template";
import TableDataSimple from '../../components/Tables/TableDataSimple';
import SimpleBackdrop from "../../components/Loading/SimpleBackdrop";
import Input from "../../components/Inputs/Input";
import { AlertInfo } from "../../components/Alerts/Alert";
import { api } from "../../services/api";
import isPermission from "../../services/permission";
import { removeMask } from "../../components/Inputs/mask";

export default function Clientes() {
    const [infoLogin] = useState(JSON.parse(sessionStorage.getItem('infoLogin')));
    const [openLoad, setOpenLoad] = useState(false);
    const [busca, setBusca] = useState("");
    const [allRows, setAllRows] = useState([]);
    const [rows, setRows] = useState([]);
    const navigate = useNavigate();
    const columns = [
        {
            id: 'id_cliente',
            numeric: false,
            label: 'CODIGO',
        },
        {
            id: 'nome',
            numeric: false,
            disablePadding: false,
            label: 'NOME/RAZÃO',
        },
        {
            id: 'cpfcnpj',
            numeric: false,
            disablePadding: false,
            label: 'CPF/CNPJ',
        },
        {
            id: 'cidade',
            numeric: false,
            disablePadding: false,
            label: 'CIDADE',
        },
        {
            id: 'telefone',
            numeric: false,
            disablePadding: false,
            label: 'TELEFONE',
        }
    ];

    function handleClick(row) {
        if (Number(row.id_empresa_cliente) === Number(infoLogin.id_empresa_usuario)) {
            navigate(`/cadastrocliente?id=${row.id_cliente}`);
        } else {
            AlertInfo('info', 'USUARIO SEM PERMISSÃO PARA EDITAR CADASTRO');
        }
    }

    function searchClient(cli) {
        if(String(cli.nome).includes(busca)) return cli;
        else if(cli.tipopessoa === 'J' && String(cli.fantasia).includes(busca)) return cli;
        else if(removeMask(cli.cpfcnpj) === removeMask(busca)) return cli;
        else if(cli.tipopessoa === 'J' && removeMask(cli.cpf_titular) === removeMask(busca)) return cli;
        return false;
    }

    async function handleSearch() {
        if(busca.length > 0){
            const fill = allRows.filter(searchClient);
            setRows(fill);
        }else{
            setRows(allRows);
        }
    }

    async function loadData() {
        setOpenLoad(true);
        try {
            const response = await api.get(`/clientes`);
            if (response && response.status === 200 && response.data) {
                if (response.data.error){
                    AlertInfo("error", response.data.message);
                }else{
                    setAllRows(response.data);
                    setRows(response.data);
                } 
            }
        } catch (error) {
            console.log(error);
            AlertInfo("error", 'NÃO POSSIVEL BUSCAR DADOS');
        }
        setOpenLoad(false);
    }

    useEffect(() => {
        loadData();
        // eslint-disable-next-line
    }, []);

    return (
        <Template menuActive="clientes">
            <SimpleBackdrop open={openLoad} />
            <Grid container spacing={1}>
                <Grid item xs={12} sm={2}>
                    {isPermission(10) && (
                        <Link to="/cadastrocliente">
                            <Button
                                variant="contained"
                                fullWidth
                            >
                                NOVO
                            </Button>
                        </Link>
                    )}
                </Grid>
                <Grid item xs={false} sm={6}></Grid>
                <Grid item xs={12} sm={4}>
                    <Input
                        label="BUSCAR"
                        value={busca}
                        setValue={setBusca}
                        onKeyUp={e => {
                            if (e.key === 'Enter') handleSearch();
                        }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <h3>Lista de Clientes</h3>
                </Grid>
                <Grid item xs={12}>
                    <TableDataSimple
                        columns={columns}
                        rows={rows}
                        handleClick={handleClick}
                    />
                </Grid>
            </Grid>
        </Template>
    );
}