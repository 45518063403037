import { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import SelectSimple from "../../../components/Selects/SelectSimple";
import SimpleBackdrop from "../../../components/Loading/SimpleBackdrop";
import Input from "../../../components/Inputs/Input";
import SelectCity from "../../../components/Selects/SelectCity";
import { AlertInfo } from "../../../components/Alerts/Alert";
import { arrayTipoPessoa } from "../../../data/options";
import { arrayUf } from "../../../data/uf";
import { dateEua, isDate } from "../../../services/date";
import { api } from "../../../services/api";

import ModalContador from "../../Clientes/ModalContador";

export default function DadosCliente({ setActiveStep, ButtonActions, communStates }) {
    const [infoLogin] = useState(JSON.parse(sessionStorage.getItem('infoLogin')));
    const [openLoad, setOpenLoad] = useState(false);
    const [openModalContador, setOpenModalContador] = useState(false);
    const [id_cliente, setIdCliente] = useState(null);
    const [tipopessoa, setTipoPessoa] = useState("F");
    const [nome, setNome] = useState("");
    const [cpfcnpj, setCpfCnpj] = useState("");
    const [fantasia, setFantasia] = useState("");
    const [titular, setTitular] = useState("");
    const [cpf_titular, setCpfTitular] = useState("");
    const [nascimento, setNascimento] = useState("");
    const [endereco, setEndereco] = useState("");
    const [numero, setNumero] = useState("");
    const [bairro, setBairro] = useState("");
    const [complemento, setComplemento] = useState("");
    const [cidade, setCidade] = useState("");
    const [uf, setUf] = useState("PA");
    const [cep, setCep] = useState("");
    const [telefone, setTelefone] = useState("");
    const [celular, setCelular] = useState("");
    const [email, setEmail] = useState("");
    const [mesmo_comprador, setMesmoComprador] = useState(true);
    const [tipopessoacomprador, setTipoPessoaComprador] = useState("F");
    const [nome_comprador, setNomeComprador] = useState("");
    const [cpfcnpj_comprador, setCpfCnpjComprador] = useState("");
    const [telefone_comprador, setTelefoneComprador] = useState("");
    const [email_comprador, setEmailComprador] = useState("");
    const [id_contador_cliente, setIdContadorCliente] = useState(0);
    const [empresa_contador, setEmpresaContador] = useState("");
    const [user_cad_cliente, setUserCadCliente] = useState(infoLogin.id_usuario || 0);
    const [id_empresa_cliente, setIdEmpresaCliente] = useState(infoLogin.id_empresa_usuario || 0);

    async function handleNext() {
        setOpenLoad(true);
        try {
            const { setInfoCliente = null } = communStates;
            const data = {
                id_cliente, tipopessoa, nome, cpfcnpj, fantasia, titular, cpf_titular,
                nascimento: isDate(nascimento), endereco, numero, bairro,
                complemento, cep, uf, cidade, user_cad_cliente, id_empresa_cliente,
                telefone, celular, email, id_contador_cliente,
                mesmo_comprador: Number(mesmo_comprador), cliente_ativo: 1,
                tipopessoacomprador, nome_comprador, cpfcnpj_comprador, telefone_comprador, email_comprador
            };
            const channel = id_cliente ? `/updatecliente` : `/createcliente`;
            const response = await api.post(channel, data);
            if (response && response.status === 200 && response.data) {
                if (response.data.error) {
                    AlertInfo('error', response.data.message);
                } else {
                    if(setInfoCliente){
                        const dataCliente = id_cliente ? data : {...data, id_cliente: response.data[0]}
                        setInfoCliente(dataCliente);
                    }
                    setActiveStep(index => index + 1);
                }
            } else {
                AlertInfo('error', 'FALHA NA OPERAÇÃO');
                console.log(response);
            }
        } catch (error) {
            console.log(error);
            AlertInfo('error', 'FALHA NA CONEXÃO');
        }
        setOpenLoad(false);
    }

    function handleBack() {
        setActiveStep(index => index - 1);
    }

    useEffect(() => {
        const { infoCliente = null } = communStates;
        if(infoCliente){
            if(infoCliente.cpfcnpj){
                setTipoPessoa(infoCliente.tipopessoa);
                setCpfCnpj(infoCliente.cpfcnpj);
            }
            if(infoCliente.id_cliente){
                setIdCliente(infoCliente.id_cliente);
                setNome(infoCliente.nome);
                setFantasia(infoCliente.fantasia);
                setTitular(infoCliente.titular);
                setCpfTitular(infoCliente.cpf_titular);
                if (infoCliente.nascimento) setNascimento(dateEua(infoCliente.nascimento));
                setEndereco(infoCliente.endereco);
                setNumero(infoCliente.numero);
                setBairro(infoCliente.bairro);
                setComplemento(infoCliente.complemento);
                setCidade(infoCliente.cidade);
                setUf(infoCliente.uf);
                setCep(infoCliente.cep);
                setTelefone(infoCliente.telefone);
                setCelular(infoCliente.celular);
                setEmail(infoCliente.email);
                setMesmoComprador(Boolean(Number(infoCliente.mesmo_comprador)));
                setTipoPessoaComprador(infoCliente.tipopessoacomprador);
                setNomeComprador(infoCliente.nome_comprador);
                setCpfCnpjComprador(infoCliente.cpfcnpj_comprador);
                setTelefoneComprador(infoCliente.telefone_comprador);
                setEmailComprador(infoCliente.email_comprador);
                setUserCadCliente(infoCliente.user_cad_cliente);
                setIdEmpresaCliente(infoCliente.id_empresa_cliente);
                if(Number(infoCliente.id_contador_cliente) > 0){
                    setIdContadorCliente(infoCliente.id_contador_cliente);
                    setEmpresaContador(infoCliente.empresa_contador);
                }
            }
        }
        // eslint-disable-next-line
    }, []);

    return (
        <Grid container spacing={1}>
            <SimpleBackdrop open={openLoad} />
            <ModalContador
                open={openModalContador}
                setOpen={setOpenModalContador}
                setIdContador={setIdContadorCliente}
                setContador={setEmpresaContador}
            />

            <Grid item xs={12}><br/></Grid>
            <Grid item xs={12} sm={2}>
                <SelectSimple
                    label="TIPO PESSOA"
                    value={tipopessoa}
                    setValue={setTipoPessoa}
                    options={arrayTipoPessoa}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <Input
                    id="nome"
                    label="NOME"
                    value={nome}
                    setValue={setNome}
                    next="cpfcnpj"
                    autoFocus
                />
            </Grid>
            <Grid item xs={12} sm={4}>
                <Input
                    id="cpfcnpj"
                    label={tipopessoa === 'F' ? 'CPF' : 'CNPJ'}
                    value={cpfcnpj}
                    setValue={setCpfCnpj}
                    mask={tipopessoa === 'F' ? 'cpf' : 'cnpj'}
                    next={tipopessoa === 'F' ? 'nascimento' : 'fantasia'}
                />
            </Grid>
            {tipopessoa === 'J' && (
                <>
                    <Grid item xs={12} sm={4}>
                        <Input
                            id="fantasia"
                            label="FANTASIA"
                            value={fantasia}
                            setValue={setFantasia}
                            next="titular"
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Input
                            id="titular"
                            label="TITULAR"
                            value={titular}
                            setValue={setTitular}
                            next="cpf_titular"
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Input
                            id="cpf_titular"
                            label="CPF TITULAR"
                            value={cpf_titular}
                            setValue={setCpfTitular}
                            mask="cpf"
                            next="nascimento"
                        />
                    </Grid>
                </>
            )}

            <Grid item xs={12} sm={2}>
                <Input
                    id="nascimento"
                    label="DATA DE NASCIMENTO"
                    value={nascimento}
                    setValue={setNascimento}
                    type="date"
                    upper={false}
                    next="endereco"
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <Input
                    id="endereco"
                    label="ENDEREÇO"
                    value={endereco}
                    setValue={setEndereco}
                    next="numero"
                />
            </Grid>
            <Grid item xs={12} sm={2}>
                <Input
                    id="numero"
                    label="NUMERO"
                    value={numero}
                    setValue={setNumero}
                    next="bairro"
                />
            </Grid>
            <Grid item xs={12} sm={2}>
                <Input
                    id="bairro"
                    label="BAIRRO"
                    value={bairro}
                    setValue={setBairro}
                    next="complemento"
                />
            </Grid>
            <Grid item xs={12} sm={5}>
                <Input
                    id="complemento"
                    label="COMPLEMENTO"
                    value={complemento}
                    setValue={setComplemento}
                    next="cep"
                />
            </Grid>
            <Grid item xs={12} sm={2}>
                <Input
                    id="cep"
                    label="CEP"
                    value={cep}
                    setValue={setCep}
                    mask="cep"
                    next="autocomplete"
                />
            </Grid>
            <Grid item xs={12} sm={1}>
                <SelectSimple
                    label="UF"
                    value={uf}
                    setValue={setUf}
                    options={arrayUf}
                />
            </Grid>
            <Grid item xs={12} sm={4}>
                <SelectCity
                    uf={uf}
                    cidade={cidade}
                    setCidade={setCidade}
                />
            </Grid>
            <Grid item xs={12} sm={3}>
                <Input
                    id="telefone"
                    label="TELEFONE"
                    value={telefone}
                    setValue={setTelefone}
                    mask="telefone"
                    next="celular"
                />
            </Grid>
            <Grid item xs={12} sm={3}>
                <Input
                    id="celular"
                    label="CELULAR"
                    value={celular}
                    setValue={setCelular}
                    mask="telefone"
                    next="email"
                />
            </Grid>
            <Grid item xs={12} sm={6}>
                <Input
                    id="email"
                    label="EMAIL"
                    value={email}
                    setValue={setEmail}
                />
            </Grid>
            <Grid item xs={12} sm={2}>
                <Button
                    fullWidth
                    color="secondary"
                    variant="contained"
                    onClick={() => setOpenModalContador(true)}
                >
                    CONTABILIDADE
                </Button>
            </Grid>
            <Grid item xs={12} sm={4}>
                {Number(id_contador_cliente) > 0 && (
                    <Input
                        label="CONTABILIDADE"
                        value={empresa_contador}
                        setValue={() => { }}
                    />
                )}
            </Grid>
            <Grid item xs={12}>
                <FormControlLabel
                    control={
                        <Checkbox checked={mesmo_comprador} onChange={() => setMesmoComprador(!mesmo_comprador)} />
                    }
                    label="MESMO COMPRADOR"
                />
            </Grid>
            {!mesmo_comprador && (
                <>
                    <Grid item xs={12}>
                        <hr />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <SelectSimple
                            label="TIPO PESSOA COMPRADOR"
                            value={tipopessoacomprador}
                            setValue={setTipoPessoaComprador}
                            options={arrayTipoPessoa}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Input
                            id="nome_comprador"
                            label="NOME COMPRADOR"
                            value={nome_comprador}
                            setValue={setNomeComprador}
                            autoFocus
                            next="cpfcnpj_comprador"
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Input
                            id="cpfcnpj_comprador"
                            label={tipopessoacomprador === 'F' ? 'CPF COMPRADOR' : 'CNPJ COMPRADOR'}
                            value={cpfcnpj_comprador}
                            setValue={setCpfCnpjComprador}
                            mask={tipopessoacomprador === 'F' ? 'cpf' : 'cnpj'}
                            next="telefone_comprador"
                        />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Input
                            id="telefone_comprador"
                            label="TELEFONE COMPRADOR"
                            value={telefone_comprador}
                            setValue={setTelefoneComprador}
                            mask='telefone'
                            next="email_comprador"
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Input
                            id="email_comprador"
                            label="EMAIL COMPRADOR"
                            value={email_comprador}
                            setValue={setEmailComprador}
                        />
                    </Grid>
                </>
            )}
            <Grid item xs={12}>
                <hr />
            </Grid>
            <Grid item xs={12}>
                <ButtonActions handleBack={handleBack} handleNext={handleNext} />
            </Grid>
        </Grid>
    );
}