import { useState, useEffect } from "react";
import Grid from '@mui/material/Grid';
import Modal from '../../components/Modal/Modal';
import TableDataSimple from "../../components/Tables/TableDataSimple";
import Input from "../../components/Inputs/Input";
import { api } from "../../services/api";

export default function ModalContador({open, setOpen, setIdContador, setContador}) {
    const [busca, setBusca] = useState("");
    const [allRows, setAllRows] = useState([]);
    const [rows, setRows] = useState([]);
    const columns = [
        {
            id: 'id_contador',
            numeric: false,
            label: 'CODIGO',
        },
        {
            id: 'empresa_contador',
            numeric: false,
            disablePadding: false,
            label: 'CONTABILIDADE',
        },
        {
            id: 'cidade_contador',
            numeric: false,
            disablePadding: false,
            label: 'CIDADE',
        },
    ];

    function handleBusca(event) {
        if(event.key === 'Enter'){
            if(String(busca).length > 0){
                const fil = allRows.filter(i => String(i.nome_contador).includes(busca) || String(i.empresa_contador).includes(busca));
                setRows(fil);
            }else{
                setRows(allRows);
            }
        }
    }

    function handleSelect(contabilidade) {
        console.log(contabilidade);
        setIdContador(contabilidade.id_contador);
        setContador(contabilidade.empresa_contador);
        setOpen(false);
    }

    async function loadData() {
        const response = await api.get(`/contadores`);
        if(response && response.status === 200 && response.data){
            if(!response.data.error){
                setAllRows(response.data);
            }
        }
    }

    useEffect(() => {
        loadData();
        // eslint-disable-next-line
    }, []);

    return (
        <Modal
            open={open}
            setOpen={setOpen}
            title="SELECIONAR CONTABILIDADE"
            maxWidth="md"
        >
            <Grid container spacing={1}>
                <Grid item xs={12} sm={8}></Grid>
                <Grid item xs={12} sm={4}>
                    <Input
                        label="BUSCAR"
                        value={busca}
                        setBusca={setBusca}
                        onKeyUp={handleBusca}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TableDataSimple
                        rows={rows}
                        columns={columns}
                        handleClick={handleSelect}
                    />
                </Grid>
            </Grid>
        </Modal>
    );
}