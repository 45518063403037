import { TextField } from "@mui/material";
import * as allMask from './mask';

export default function Input({ label, value, setValue, upper = true, next = null, mask = null, ...rest }) {
    const isMask = (mask && allMask[mask]) ? allMask[mask] : null;
    function handleValue(e) {
        if(isMask){
            setValue(isMask(e.target.value));
        }else if(upper){
            var ss = e.target.selectionStart;
            var se = e.target.selectionEnd;
            e.target.value = e.target.value.toUpperCase();
            e.target.selectionStart = ss;
            e.target.selectionEnd = se;
            setValue(e.target.value);
        }else{
            setValue(e.target.value);
        }
    }

    function nextInput(e){
        if(next && e.key === 'Enter'){
            let nex = document.getElementById(next);
            if(nex) nex.focus();
        }
    }

    return (
        <TextField
            label={label}
            variant="outlined"
            fullWidth
            value={value}
            size="small"
            onChange={handleValue}
            onKeyUp={nextInput}
            {...rest}
        />
    );
}