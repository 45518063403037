import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import SelectSimple from "../../../components/Selects/SelectSimple";
import SimpleBackdrop from "../../../components/Loading/SimpleBackdrop";
import Input from "../../../components/Inputs/Input";
import { AlertInfo } from "../../../components/Alerts/Alert";
import { api } from "../../../services/api";
import { converterMoeda, displayMoney } from "../../../services/displayValue";
import { dateEuaExat, dateEuaHrM, isDate } from "../../../services/date";
import { formasPagamento } from "../../../data/options";
import { removeMask } from "../../../components/Inputs/mask";
import isPermission from "../../../services/permission";

export default function Certificado({ setActiveStep, ButtonActions, communStates }) {
    const navigate = useNavigate();
    const [infoLogin] = useState(JSON.parse(sessionStorage.getItem('infoLogin')));
    const [agr_id, setAgrId] = useState(Number(infoLogin.is_agr) === 1 ? infoLogin.id_usuario : "0");
    const [arrayAgr, setArrayAgr] = useState([]);
    const [id_empresa_certificado] = useState(infoLogin.id_empresa_usuario);
    const [openLoad, setOpenLoad] = useState(false);
    const [arrayProds, setArrayProds] = useState([]);
    const [produto_id, setProdutoId] = useState("0");
    const [tabelaPreco, setTabelaPreco] = useState("preco1");
    const [custo_produto, setCustoProduto] = useState(0);
    const [valor_produto, setValorProduto] = useState(displayMoney(0));
    const [cod_serpro, setCodSerpro] = useState("");
    const [pagamento, setPagamento] = useState("3");
    const [data_agendamento, setDataAgendamento] = useState(dateEuaHrM());
    const [is_video, setIsVideo] = useState(false);
    const [cei, setCei] = useState("");

    function dataSerproPF(infoCliente) {
        let data = {
            "responsavel": {
                "nome": infoCliente.nome,
                "dataNascimento": infoCliente.nascimento,
                "cpf": removeMask(infoCliente.cpfcnpj),
                "contato": {
                    "email": infoCliente.email,
                    "telefone": removeMask(infoCliente.telefone),
                },
            }
        };

        if (String(cei).length > 0) {
            data.responsavel.identificacaoAdicional = {
                ceiInss: removeMask(cei)
            }
        }

        return data;
    }

    function dataSerproPJ(infoCliente) {
        const data = {
            "organizacao": {
                "nome": infoCliente.nome,
                "cnpj": removeMask(infoCliente.cpfcnpj),
                "municipio": infoCliente.cidade,
                "uf": infoCliente.uf,
                "representanteLegal": {
                    "nome": infoCliente.titular,
                    "dataNascimento": infoCliente.nascimento,
                    "cpf": removeMask(infoCliente.cpf_titular)
                }
            },
            "responsavel": {
                "nome": infoCliente.titular,
                "dataNascimento": infoCliente.nascimento,
                "cpf": removeMask(infoCliente.cpf_titular),
                "contato": {
                    "email": infoCliente.email,
                    "telefone": removeMask(infoCliente.telefone)
                },
            }
        }
        return data;
    }

    async function handleNext() {
        setOpenLoad(true);
        let txt = '';
        try {
            const { infoCliente = null } = communStates;
            if (infoCliente && infoCliente.id_cliente) {
                const dataSerpro = infoCliente.tipopessoa === 'F' ? dataSerproPF(infoCliente) : dataSerproPJ(infoCliente);
                const dataRenove = {
                    agr_id, id_empresa_certificado, cliente_id: infoCliente.id_cliente,
                    produto_id, valor_produto: converterMoeda(valor_produto), pagamento,
                    data_pedido: dateEuaExat(), cei, data_agendamento: isDate(data_agendamento),
                    is_video: Number(is_video), custo_produto
                };
                const response = await api.post(`/solicitarcertificadoerpro/${cod_serpro}`, { dataSerpro, dataRenove });
                if (response && response.status === 200 && response.data) {
                    if (response.data.success) {
                        setOpenLoad(false);
                        AlertInfo('success', 'DADOS GRAVADOS').then(() => {
                            // navigator.clipboard.writeText(response.data.data);
                            navigate('/certificados');
                        });
                        return;
                    } else if (response.data.message && response.data.message.errors) {
                        txt = response.data.message.errors[0].message;
                    }
                }
                console.log(response);
                AlertInfo('error', 'FALHA NA OPERAÇÃO', txt);
            } else {
                AlertInfo('info', 'NÃO FOI POSSIVEL USAR OS DADOS DO CLIENTE');
            }
        } catch (error) {
            console.log(error);
            AlertInfo('error', 'FALHA NA CONEXÃO');
        }
        setOpenLoad(false);
    }

    function handleBack() {
        setActiveStep(index => index - 1);
    }

    async function loadData() {
        setOpenLoad(true);
        try {
            const response = await api.get(`/produtos`);
            if (response && response.status === 200 && response.data) {
                if (!response.data.error) {
                    const prods = response.data;
                    setArrayProds(prods.map(i => ({ ...i, value: i.id_produto, label: i.descricao })));
                    setProdutoId(response.data[0].id_produto);
                }
            }
            const getAgrs = await api.get(`/usuarios?id_empresa_usuario=${infoLogin.id_empresa_usuario}&is_agr=1`);
            if (getAgrs && getAgrs.status === 200 && getAgrs.data) {
                if (!getAgrs.data.error) {
                    const agrs = getAgrs.data;
                    const formatAgrs = agrs.map(i => ({ ...i, value: i.id_usuario, label: i.alias }));
                    setArrayAgr([{ value: 0, label: "---" }, ...formatAgrs]);
                }
            }
        } catch (error) {
            console.log(error);
        }
        setOpenLoad(false);
    }

    useEffect(() => {
        if (arrayProds.length > 0) {
            const preco = arrayProds.filter(i => Number(i.id_produto) === Number(produto_id));
            if (preco.length === 1) {
                setValorProduto(displayMoney(preco[0][tabelaPreco]));
                setCodSerpro(preco[0].cod_serpro);
                setCustoProduto(preco[0].revenda);
            } else {
                setValorProduto(displayMoney(0));
                setCodSerpro("");
            }
        }
        // eslint-disable-next-line
    }, [produto_id, tabelaPreco]);

    useEffect(() => {
        loadData();
        // eslint-disable-next-line
    }, []);

    return (
        <Grid container spacing={1}>
            <SimpleBackdrop open={openLoad} />
            <Grid item xs={12}><br /></Grid>
            <Grid item xs={12} sm={3}>
                <SelectSimple
                    label="CERTIFICADO"
                    value={produto_id}
                    setValue={setProdutoId}
                    options={arrayProds}
                />
            </Grid>
            <Grid item xs={12} sm={3}>
                <SelectSimple
                    label="TABELA DE PREÇO"
                    value={tabelaPreco}
                    setValue={setTabelaPreco}
                    options={[
                        { value: 'preco1', label: 'PREÇO 1' },
                        { value: 'preco2', label: 'PREÇO 2' },
                        { value: 'preco3', label: 'PREÇO 3' },
                    ]}
                />
            </Grid>
            <Grid item xs={12} sm={3}>
                <Input
                    id="valor_produto"
                    label="VALOR CERTIFICADO"
                    value={valor_produto}
                    setValue={isPermission(15) ? setValorProduto : () => {}}
                    upper={false}
                    next="cei"
                />
            </Grid>
            <Grid item xs={12} sm={3}>
                <Input
                    id="cei"
                    label="CEI"
                    value={cei}
                    setValue={setCei}
                    next="data_agendamento"
                />
            </Grid>
            <Grid item xs={12} sm={3}>
                <SelectSimple
                    label="PAGAMENTO"
                    value={pagamento}
                    setValue={setPagamento}
                    options={formasPagamento}
                />
            </Grid>
            <Grid item xs={12} sm={3}>
                <Input
                    id="data_agendamento"
                    label="AGENDAMENTO"
                    value={data_agendamento}
                    setValue={setDataAgendamento}
                    type="datetime-local"
                    upper={false}
                    InputLabelProps={{
                        shrink: true,
                    }}
                />
            </Grid>
            <Grid item xs={12} sm={3}>
                <SelectSimple
                    label="AGR VALIDAÇÃO"
                    value={agr_id}
                    setValue={setAgrId}
                    options={arrayAgr}
                />
            </Grid>
            <Grid item xs={12} sm={3}>
                <FormControlLabel
                    control={
                        <Checkbox checked={is_video} onChange={() => setIsVideo(!is_video)} />
                    }
                    label="VIDEO CONFERENCIA"
                />
            </Grid>

            <Grid item xs={12}>
                <ButtonActions handleBack={handleBack} handleNext={handleNext} isFinal />
            </Grid>
        </Grid>
    );
}