import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Fab from '@mui/material/Fab';
import SearchIcon from '@mui/icons-material/Search';
import Template from "../../template/Template";
import TableDataSimple from '../../components/Tables/TableDataSimple';
import SimpleBackdrop from "../../components/Loading/SimpleBackdrop";
import LabelPagamento from "../../components/Labels/LabelPagamento";
import { AlertInfo } from "../../components/Alerts/Alert";
import { api } from "../../services/api";
import { dateBrl, endMes, startMes } from "../../services/date";
import isPermission from "../../services/permission";

import DrawerFiltroCertficados from "./DrawerFiltroCertficados";
import ModalCertificado from "./ModalCertificado";
import DrawerRelatorio from "./DrawerRelatorio";
import ModalReciboAvulso from "./ModalReciboAvulso";

export default function Certificados() {
    const [infoLogin] = useState(JSON.parse(sessionStorage.getItem('infoLogin')));
    const [openLoad, setOpenLoad] = useState(false);
    const [openModalCertificado, setOpenModalCertificado] = useState(false);
    const [infoModalCertificado, setInfoModalCertificado] = useState(null);
    const [rows, setRows] = useState([]);
    const [openFilters, setOpenFilters] = useState(false);
    const [openRel, setOpenRel] = useState(false);
    const [openReciboAvulso, setOpenReciboAvulso] = useState(false);
    const columns = [
        {
            id: 'numero_referencia',
            numeric: false,
            label: 'CODIGO',
        },
        {
            id: 'nome',
            numeric: false,
            label: 'NOME/RAZÃO',
        },
        {
            id: 'descricao',
            numeric: false,
            label: 'CERTIFICADO',
        },
        {
            id: 'situacao',
            numeric: false,
            label: 'STATUS',
        },
        {
            id: 'alias',
            numeric: false,
            label: 'AGR',
        },
        {
            id: 'data_pedido',
            numeric: false,
            label: 'PEDIDO',
            format: dateBrl,
        },
        {
            id: 'validade_fim',
            numeric: false,
            label: 'VENCIMENTO',
            format: (dt) => dt ? dateBrl(dt) : "",
        },
        {
            id: 'pagamento',
            numeric: false,
            label: 'PAGAMENTO',
            format: LabelPagamento,
        },
    ];

    function handleClick(row) {
        setInfoModalCertificado(row);
        setOpenModalCertificado(true);
    }

    async function loadData() {
        setOpenLoad(true);
        try {
            const data = { id_empresa_certificado: infoLogin.id_empresa_usuario, start: startMes(), end: endMes() };
            const response = await api.post(`/certificados`, data);
            if(response && response.status === 200 && response.data){
                if(response.data.error) AlertInfo("error", response.data.message);
                else setRows(response.data);
            }
        } catch (error) {
            console.log(error);
            AlertInfo("error", 'NÃO POSSIVEL BUSCAR DADOS');
        }
        setOpenLoad(false);
    }

    useEffect(() => {
        loadData();
        // eslint-disable-next-line
    }, []);

    return (
        <Template menuActive="certificados">
            <SimpleBackdrop open={openLoad} />
            <DrawerFiltroCertficados
                open={openFilters}
                setOpen={setOpenFilters}
                setCertificados={setRows}
            />
            <DrawerRelatorio
                open={openRel}
                setOpen={setOpenRel}
            />
            <ModalCertificado
                open={openModalCertificado}
                setOpen={setOpenModalCertificado}
                info={infoModalCertificado}
                loadData={loadData}
            />
            <ModalReciboAvulso
                open={openReciboAvulso}
                setOpen={setOpenReciboAvulso}
            />

            <Grid container spacing={1}>
                <Grid item xs={12} sm={2}>
                    {isPermission(14) && (
                        <Link to="/novopedido">
                            <Button
                                variant="contained"
                                fullWidth
                            >
                                NOVO
                            </Button>
                        </Link>
                    )}
                </Grid>
                <Grid item xs={12} sm={2}>
                    {isPermission(20) && (
                        <Button
                            variant="contained"
                            fullWidth
                            onClick={() => setOpenRel(true)}
                        >
                            RELATORIOS
                        </Button>
                    )}
                </Grid>
                <Grid item xs={12} sm={2}>
                    {isPermission(22) && (
                        <Button
                            variant="contained"
                            fullWidth
                            onClick={() => setOpenReciboAvulso(true)}
                        >
                            RECIBO
                        </Button>
                    )}
                </Grid>
                <Grid item xs={false} sm={5}></Grid>
                <Grid item xs={12} sm={1} justifyContent={'flex-end'} container>
                    <Fab size="small" color="primary" onClick={() => setOpenFilters(true)}>
                        <SearchIcon />
                    </Fab>
                </Grid>
                <Grid item xs={12}>
                    <h3>Lista de Certificados</h3>
                </Grid>
                <Grid item xs={12}>
                    <TableDataSimple
                        columns={columns}
                        rows={rows}
                        handleClick={handleClick}
                    />
                </Grid>
            </Grid>
        </Template>
    );
}