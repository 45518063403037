const number = /^[0-9]+$/;

export function cpf(value){
    let arrayValue = value.split('');
    let mascara = value;
    let arrayMask = [];

    if(arrayValue.length <= 14){
        arrayMask = arrayValue.filter(digito => digito.match(number)).map((digito, index) => {
            if(index === 3 || index === 6){
                return `.${digito}`;
            }else if(index === 9){
                return `-${digito}`;
            }else{
                return digito;
            }
        });
        mascara = arrayMask.join('');
    }else{
        mascara = arrayValue.slice(0, 14).join('');
    }
    return mascara;
}

export function cnpj(value){
    let arrayValue = value.split('');
    let mascara = value;
    let arrayMask = [];

    if(arrayValue.length <= 18){
        arrayMask = arrayValue.filter(digito => digito.match(number)).map((digito, index) => {
            if(index === 2 || index === 5){
                return `.${digito}`;
            }else if(index === 8){
                return `/${digito}`;
            }else if(index === 12) {
                return `-${digito}`;
            }else{
                return digito;
            }
        });
        mascara = arrayMask.join('');
    }else{
        mascara = arrayValue.slice(0, 18).join('');
    }
    return mascara;
}

export function cep(value){
    let arrayValue = value.split('');
    let mascara = value;
    let arrayMask = [];

    if(arrayValue.length <= 9){
        arrayMask = arrayValue.filter(digito => digito.match(number)).map((digito, index) => {
            if(index === 5){
                return `-${digito}`;
            }else{
                return digito;
            }
        });
        mascara = arrayMask.join('');
    }else{
        mascara = arrayValue.slice(0, 9).join('');
    }
    return mascara;
}

export function telefone(value){
    let arrayValue = value.split('');
    let arrayMask = [];
    let mascara = value;

    if(arrayValue.length <= 14){
        arrayMask = arrayValue.filter(digito => digito.match(number)).map((digito, index) => {
            if(index === 0){
                return `(${digito}`;
            }else if(index === 2){
                return `)${digito}`;
            }else if(index === 7) {
                return `-${digito}`;
            }else{
                return digito;
            }
        });
        mascara = arrayMask.join('');
    }else{
        mascara = arrayValue.slice(0, 14).join('');
    }
    return mascara;
}

export function maskNumber(e, setFunction) {
    let { value } = e.target;
    if(value.match(number) || value === '') setFunction(value);
}

export function maskDinheiro(e, setFunction) {
    let { value } = e.target;
    let arrayValue = value.split('');
    let arrayMask = [];
    let mascara;

    if(arrayValue.length > 0){
        arrayMask = arrayValue.filter(digito => {
            if(digito.match(number)) return digito;
            if(digito === '.' || digito === ',' || digito === '%') return digito;
            return '';
        });
        mascara = arrayMask.join('');
        setFunction(mascara);
    }else{
        setFunction('');
    }
}

export function removeMask(value) {
    // eslint-disable-next-line
    const noMask = value.replace(/\.|\-|\/|\(|\)/g, '');
    return noMask;
}