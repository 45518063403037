import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import Grid from "@mui/material/Grid";
import SelectSimple from "../../../components/Selects/SelectSimple";
import Input from "../../../components/Inputs/Input";
import SimpleBackdrop from "../../../components/Loading/SimpleBackdrop";
import { AlertInfo } from "../../../components/Alerts/Alert";
import { api } from "../../../services/api";

export default function Pesquisar({ setActiveStep, ButtonActions, communStates }) {
    const [openLoad, setOpenLoad] = useState(false);
    const [tipopessoa, setTipoPessoa] = useState("F");
    const [cpfcnpj, setCpfCnpj] = useState("");
    const [searchParms] = useSearchParams();

    async function nextStep(doc) {
        setOpenLoad(true);
        try {
            const { setInfoCliente = null } = communStates;
            const response = await api.post(`/cliente`, {cpfcnpj: doc});
            if(response && response.status === 200 && response.data){
                if(response.data.error){
                    AlertInfo('error', response.data.message);
                }else if(response.data[0] && response.data[0].id_cliente){
                    if(setInfoCliente) setInfoCliente(response.data[0]);
                }else{
                    if(setInfoCliente) setInfoCliente({cpfcnpj, tipopessoa});
                }
                setActiveStep(1);
            }else{
                AlertInfo('error', 'FALHA NA OPERAÇÃO');
            }
        } catch (error) {
            console.log(error);
            AlertInfo('error', 'FALHA NA CONEXÃO');
        }
        setOpenLoad(false);
    }

    async function handleNext() {
        if((tipopessoa === "F" && cpfcnpj.length === 14) || (tipopessoa === "J" && cpfcnpj.length === 18)){
            nextStep(cpfcnpj);
        }else{
            AlertInfo('info', 'PREENCHA CPF OU CNPJ VALIDO');
        }
    }

    const handleBack = () => {}

    async function loadData() {
        const id = searchParms.get('id');
        if(id) nextStep(id);
    }

    useEffect(() => {
        loadData();
        // eslint-disable-next-line
    }, []);

    return(
        <Grid container spacing={1}>
            <SimpleBackdrop open={openLoad} />
            <Grid item xs={12}><br/></Grid>
            <Grid item xs={12} sm={3}>
                <SelectSimple
                    label="TIPO PESSOA"
                    value={tipopessoa}
                    setValue={setTipoPessoa}
                    options={[
                        {value:'F', label:'FISICA'},
                        {value:'J', label:'JURIDICA'},
                    ]}
                />
            </Grid>
            <Grid item xs={12} sm={3}>
                <Input
                    label={tipopessoa === 'F' ? "CPF" : "CNPJ"}
                    value={cpfcnpj}
                    setValue={setCpfCnpj}
                    mask={tipopessoa === 'F' ? "cpf" : "cnpj"}
                    onKeyUp={e => {
                        if(e.key === 'Enter') handleNext();
                    }}
                    autoFocus
                />
            </Grid>
            <Grid item xs={12}>
                <ButtonActions handleBack={handleBack} handleNext={handleNext} />
            </Grid>
        </Grid>
    );
}