import { useState } from "react";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Modal from "../../components/Modal/Modal";
import Input from "../../components/Inputs/Input";
import { dateEuaHrM } from "../../services/date";
import { apiUrl } from "../../services/api";
import SelectSimple from "../../components/Selects/SelectSimple";
import { arrayTipoPessoa, formasPagamento } from "../../data/options";
import { converterMoeda } from "../../services/displayValue";

export default function ModalReciboAvulso({ open, setOpen }) {
    const [infoLogin] = useState(JSON.parse(sessionStorage.getItem('infoLogin')));
    const [nome, setNome] = useState("");
    const [tipopessoa, setTipoPessoa] = useState("F");
    const [cpfcnpj, setCpfCnpj] = useState("");
    const [valor_produto, setValorProduto] = useState("");
    const [descricao, setDescricao] = useState("");
    const [pagamento, setPagamento] = useState("0");
    const [data_agendamento, setDataAgendamento] = useState(dateEuaHrM());

    function handleKey(event){
        if(event.key === 'Enter') event.preventDefault();
    }

    return (
        <Modal
            open={open}
            setOpen={setOpen}
            title="EMITIR COBRANÇA"
            maxWidth="xs"
        >
            {/* <SimpleBackdrop open={openLoad} /> */}
            <form 
                action={`${apiUrl}/reciboavulso`} 
                method="POST" 
                target="_blank" 
                onKeyDown={handleKey} 
            >
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <SelectSimple
                            label="TIPO PESSOA"
                            value={tipopessoa}
                            setValue={setTipoPessoa}
                            options={arrayTipoPessoa}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <input type="hidden" value={infoLogin.id_empresa_usuario} name="id_empresa_usuario" />
                        <input type="hidden" value={infoLogin.nome_completo} name="nome_completo" />
                        <Input
                            id="nome"
                            label="NOME CLIENTE"
                            value={nome}
                            setValue={setNome}
                            type="text"
                            name="nome"
                            next="cpfcnpj"
                            autoFocus
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Input
                            id="cpfcnpj"
                            label={tipopessoa === 'F' ? 'CPF' : 'CNPJ'}
                            value={cpfcnpj}
                            setValue={setCpfCnpj}
                            type="text"
                            mask={tipopessoa === 'F' ? 'cpf' : 'cnpj'}
                            name="cpfcnpj"
                            next="valor_produto"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <input name="valor_produto" value={converterMoeda(valor_produto)} type="hidden" />
                        <Input
                            id="valor_produto"
                            label="VALOR RECIBO"
                            value={valor_produto}
                            setValue={setValorProduto}
                            type="text"
                            next="descricao"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Input
                            label="DESCRIÇÃO"
                            value={descricao}
                            setValue={setDescricao}
                            type="text"
                            name="descricao"
                            id="descricao"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <SelectSimple
                            label="PAGAMENTO"
                            value={pagamento}
                            setValue={setPagamento}
                            options={formasPagamento}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Input
                            label="DATA RECIBO"
                            value={data_agendamento}
                            setValue={setDataAgendamento}
                            name="data_agendamento"
                            id="data_agendamento"
                            type="datetime-local"
                            upper={false}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <Button
                            variant="contained"
                            fullWidth
                            type="submit"
                        // onClick={emitirCobranca}
                        >
                            EMITIR COBRANÇA
                        </Button>
                    </Grid>
                </Grid>
            </form>
        </Modal>
    );
}