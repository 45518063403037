import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Grid from "@mui/material/Grid";
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Button from "@mui/material/Button";
import Fab from '@mui/material/Fab';
import PersonIcon from '@mui/icons-material/Person';
import Modal from "../../components/Modal/Modal";
import InputDisplay from "../../components/Inputs/InputDisplay";
import Input from "../../components/Inputs/Input";
import SelectSimple from "../../components/Selects/SelectSimple";
import SimpleBackdrop from "../../components/Loading/SimpleBackdrop";
import { AlertConfirm, AlertInfo } from "../../components/Alerts/Alert";
import { dateBrl, dateEua, dateEuaHrM, isDate } from "../../services/date";
import { converterMoeda, displayMoney } from "../../services/displayValue";
import { formasPagamento } from "../../data/options";
import { api, apisafe2pay, apiUrl } from "../../services/api";

import ModalCobranca from "./ModalCobranca";

export default function ModalCertificado({ open, setOpen, info, loadData }) {
    const infoLogin = JSON.parse(sessionStorage.getItem("infoLogin"));
    const [openLoad, setOpenLoad] = useState(false);
    const [data_pedido, setDataPedido] = useState(dateEua());
    const [validade_inicio, setValidadeInicio] = useState("");
    const [validade_fim, setValidadeFim] = useState("");
    const [valor_produto, setValorProduto] = useState(displayMoney(0));
    const [pagamento, setPagamento] = useState("3");
    const [data_agendamento, setDataAgendamento] = useState(dateEuaHrM());
    const [is_video, setIsVideo] = useState(false);
    const [cei, setCei] = useState("");
    const [openModalCobranca, setOpenModalCobranca] = useState(false);
    const [isLink, setIsLink] = useState(false);

    async function handleSave() {
        setOpenLoad(true);
        try {
            const where = { id_certificado: info.id_certificado };
            const data = {
                valor_produto: converterMoeda(valor_produto),
                pagamento, data_agendamento: isDate(data_agendamento),
                is_video: Number(is_video), cei,
                data_pedido: isDate(data_pedido),
                validade_inicio: isDate(validade_inicio),
                validade_fim: isDate(validade_fim)
            };
            const response = await api.post(`/updatecertificado`, { where, data });
            if (response && response.status === 200 && response.data) {
                if (response.data.success) {
                    loadData();
                    setOpen(false);
                    setOpenLoad(false);
                    return AlertInfo('success', 'DADOS GRAVADOS');
                }
            }
            console.log(response);
            AlertInfo('error', 'FALHA NA OPERAÇÃO');
        } catch (error) {
            console.log(error);
            AlertInfo('error', 'FALHA NA CONEXÃO');
        }
        setOpenLoad(false);
    }

    async function handleCobranca() {
        if (["1", "6"].includes(pagamento)) {
            if (info.id_safe_2_pay) {
                const title = "Já existe uma cobrança emitida para esse certificado! Emitir outra?";
                const txt = "A cobrança atual será substituida pela nova";
                AlertConfirm("info", title, txt).then(res => {
                    if (res.isConfirmed) {
                        setIsLink(false);
                        setOpenModalCobranca(true);
                    }
                });
            } else {
                setOpenModalCobranca(true);
            }
        } else {
            AlertInfo('info', "FORMA DE PAGAMENTO NÃO DISPONIVEL");
        }
    }

    function handleOpenCobranca() {
        if (pagamento === "1") {
            window.open(info.link_payment);
        } else if (pagamento === "6") {
            setIsLink(true);
            setOpenModalCobranca(true);
        }
    }

    async function handleConsult() {
        setOpenLoad(true);
        try {
            const response = await apisafe2pay.get(`/transaction/Get?Id=${info.id_safe_2_pay}`, {
                headers: {
                    safe2pay: infoLogin.api_safe2pay
                }
            });
            if (response && response.status === 200 && response.data) {
                if (response.data.ResponseDetail && response.data.ResponseDetail.Status) {
                    const { Status, Message, PaymentDate = null } = response.data.ResponseDetail;
                    const data = { status_safe2pay: Status, message_safe2pay: Message, data_pagamento: PaymentDate }
                    const where = { id_certificado: info.id_certificado };
                    const responseApi = await api.post(`/updatecertificado`, { data, where });
                    console.log(responseApi);
                    loadData();
                    AlertInfo('success', Message);
                }
            }
        } catch (error) {
            console.log(error);
            AlertInfo('error', 'FALHA NA CONEXÃO');
        }
        setOpenLoad(false);
    }

    async function handleRecibo() {
        window.open(`${apiUrl}/recibocertificado/${info.id_certificado}`);
    }

    async function cancelPedido() {
        if (info.situacao === 'Pendente') {
            AlertConfirm('info', 'CENCELAR PEDIDO?').then(async res => {
                if (res.isConfirmed) {
                    setOpenLoad(true);
                    try {
                        const data = { situacao: 'CANCELADO' };
                        const where = { id_certificado: info.id_certificado };
                        const response = await api.post(`/updatecertificado`, { data, where });
                        if (response && response.status === 200 && response.data) {
                            if (response.data.success) {
                                loadData();
                                setOpenLoad(false);
                                setOpen(false);
                                return AlertInfo('success', 'PEDIDO CANCELADO');
                            }
                        }
                        console.log(response);
                        AlertInfo('error', 'FALHA NA OPERAÇÃO');
                    } catch (error) {
                        console.log(error);
                        AlertInfo('FALHA NA CONEXÃO');
                    }
                    setOpenLoad(false);
                }
            });
        } else {
            AlertInfo('info', 'Ação não permitida', 'Certificado não está mais como Pendente');
        }
    }

    async function handleConsultCertificado() {
        setOpenLoad(true);
        try {
            const response = await api.get(`/consultarcertificado/${info.numero_referencia}`);
            console.log(response);
            if(response && response.status === 200 && response.data) {
                if(response.data.success){
                    loadData();
                    setOpen(false);
                    setOpenLoad(false);
                    return AlertInfo('success', response.data.data);
                }
            }
        } catch (error) {
            console.log(error);
            AlertInfo('error', 'FALHA NA CONEXÃO');
        }
        setOpenLoad(false);
    }

    useEffect(() => {
        if (info && info.id_certificado) {
            setValorProduto(displayMoney(info.valor_produto));
            setPagamento(info.pagamento);
            setDataAgendamento(dateEuaHrM(info.data_agendamento));
            setIsVideo(Boolean(Number(info.is_video)));
            setCei(info.cei);
            if(isDate(info.data_pedido)) setDataPedido(dateEua(info.data_pedido));
            if(isDate(info.validade_inicio)) setValidadeInicio(dateEua(info.validade_inicio));
            if(isDate(info.validade_fim)) setValidadeFim(dateEua(info.validade_fim));
        }
        // eslint-disable-next-line
    }, [info]);

    return (
        <Modal
            open={open}
            setOpen={setOpen}
            title={(info && info.id_certificado) ? `DADOS PEDIDO ${info.fantasia_empresa}` : "DADOS PEDIDO"}
            maxWidth="lg"
        >
            <SimpleBackdrop open={openLoad} />
            <ModalCobranca
                open={openModalCobranca}
                setOpen={setOpenModalCobranca}
                info={info}
                pagamento={pagamento}
                isLink={isLink}
            />

            {info && info.id_certificado && (
                <Grid container spacing={1}>
                    <Grid item xs={12} sm={2}>
                        <InputDisplay
                            label="CODIGO"
                            value={info.numero_referencia}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <InputDisplay
                            label="CERTIFICADO"
                            value={info.descricao}
                        />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <FormControlLabel
                            control={
                                <Checkbox checked={is_video} onChange={() => setIsVideo(!is_video)} />
                            }
                            label="VIDEO CONFERENCIA"
                        />
                    </Grid>
                    <Grid item xs={12} sm={2} justifyContent={'flex-end'} container>
                        <Link to={`/cadastrocliente?id=${info.cliente_id}`}>
                            <Fab size="small" color="secondary">
                                <PersonIcon />
                            </Fab>
                        </Link>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <InputDisplay
                            label="TIPOPESSOA"
                            value={info.tipopessoa === 'F' ? "FISICA" : "JURIDICA"}
                        />
                    </Grid>
                    <Grid item xs={12} sm={7}>
                        <InputDisplay
                            label={info.tipopessoa === 'F' ? "NOME" : "RAZÃO SOCIAL"}
                            value={info.nome}
                        />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <InputDisplay
                            label={info.tipopessoa === 'F' ? "CPF" : "CNPJ"}
                            value={info.cpfcnpj}
                        />
                    </Grid>

                    {info.tipopessoa === 'J' && (
                        <>
                            <Grid item xs={12} sm={3}>
                                <InputDisplay
                                    label="FANTASIA"
                                    value={info.fantasia}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <InputDisplay
                                    label="TITULAR"
                                    value={info.titular}
                                />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <InputDisplay
                                    label="CPF TITULAR"
                                    value={info.cpf_titular}
                                />
                            </Grid>
                        </>
                    )}
                    <Grid item xs={12} sm={2}>
                        <InputDisplay
                            label="DATA DE NASCIMENTO"
                            value={dateBrl(info.nascimento)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <InputDisplay
                            label="TELEFONE"
                            value={info.telefone}
                        />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <InputDisplay
                            label="CELULAR"
                            value={info.celular}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <InputDisplay
                            label="EMAIL"
                            value={info.email}
                        />
                    </Grid>
                    {!info.mesmo_comprador && (
                        <>
                            <Grid item xs={12} sm={6}>
                                <InputDisplay
                                    label="NOME COMPRADOR"
                                    value={info.nome_comprador}
                                />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <InputDisplay
                                    label={info.tipopessoacomprador === 'F' ? 'CPF COMPRADOR' : 'CNPJ COMPRADOR'}
                                    value={info.cpfcnpj_comprador}
                                />
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <InputDisplay
                                    label="TELEFONE COMPRADOR"
                                    value={info.telefone_comprador}
                                />
                            </Grid>
                        </>
                    )}
                    {info.id_safe_2_pay && (
                        <>
                            <Grid item xs={12} sm={2}>
                                <InputDisplay
                                    label="CODIGO COBRANÇA"
                                    value={info.id_safe_2_pay}
                                />
                            </Grid>
                            <Grid item xs={12} sm={2}>
                                <InputDisplay
                                    label="STATUS COBRANÇA"
                                    value={info.message_safe2pay}
                                />
                            </Grid>
                            <Grid item xs={12} sm={2}>
                                <InputDisplay
                                    label="DATA VENCIMENTO"
                                    value={info.vencimento_cobranca ? dateBrl(info.vencimento_cobranca) : ""}
                                />
                            </Grid>
                            <Grid item xs={12} sm={2}>
                                <InputDisplay
                                    label="DATA PAGAMENTO"
                                    value={info.data_pagamento ? dateBrl(info.data_pagamento) : ""}
                                />
                            </Grid>
                            <Grid item xs={12} sm={2} alignItems={'center'} container>
                                <Button
                                    variant="contained"
                                    fullWidth
                                    color="secondary"
                                    onClick={handleConsult}
                                >
                                    CONSULTAR
                                </Button>
                            </Grid>
                            <Grid item xs={12} sm={2} alignItems={'center'} container>
                                <Button
                                    variant="contained"
                                    fullWidth
                                    color="secondary"
                                    onClick={handleOpenCobranca}
                                >
                                    ABRIR
                                </Button>
                            </Grid>
                        </>
                    )}

                    <Grid item xs={12} sm={2}>
                        <Input
                            label="DATA PEDIDO"
                            value={data_pedido}
                            setValue={setDataPedido}
                            upper={false}
                            type="date"
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <Input
                            label="EMISSÃO"
                            value={validade_inicio}
                            setValue={setValidadeInicio}
                            upper={false}
                            type="date"
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <Input
                            label="VALIDADE"
                            value={validade_fim}
                            setValue={setValidadeFim}
                            upper={false}
                            type="date"
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <InputDisplay
                            label="SITUAÇÃO"
                            value={info.situacao}
                        />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <InputDisplay
                            label="AGR"
                            value={info.alias}
                        />
                    </Grid>
                    <Grid item xs={12} sm={2} alignItems={'center'} container>
                        <Button
                            variant="contained"
                            fullWidth
                            color="secondary"
                            onClick={() => handleConsultCertificado()}
                        >
                            CONSULTAR
                        </Button>
                    </Grid>

                    <Grid item xs={12} sm={3}>
                        <Input
                            label="VALOR CERTIFICADO"
                            value={valor_produto}
                            setValue={setValorProduto}
                            upper={false}
                        />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Input
                            label="CEI"
                            value={cei}
                            setValue={setCei}
                            upper={false}
                        />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <SelectSimple
                            label="PAGAMENTO"
                            value={pagamento}
                            setValue={setPagamento}
                            options={formasPagamento}
                        />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Input
                            label="AGENDAMENTO"
                            value={data_agendamento}
                            setValue={setDataAgendamento}
                            type="datetime-local"
                            upper={false}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Grid>

                    {info.situacao !== 'CANCELADO' && (
                        <>
                            <Grid item xs={12} sm={3}>
                                <Button
                                    variant="contained"
                                    fullWidth
                                    onClick={handleSave}
                                >
                                    SALVAR ALTERAÇÃO
                                </Button>
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <Button
                                    variant="contained"
                                    fullWidth
                                    color="secondary"
                                    onClick={handleRecibo}
                                >
                                    GERAR RECIBO
                                </Button>
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                {infoLogin && String(infoLogin.api_safe2pay).length > 0 && (
                                    <Button
                                        variant="contained"
                                        fullWidth
                                        color="secondary"
                                        onClick={handleCobranca}
                                    >
                                        GERAR COBRANÇA
                                    </Button>
                                )}
                            </Grid>
                            <Grid item xs={12} sm={3}>
                                <Button
                                    variant="contained"
                                    fullWidth
                                    onClick={cancelPedido}
                                >
                                    CANCELAR PEDIDO
                                </Button>
                            </Grid>
                        </>
                    )}
                </Grid>
            )}
        </Modal>
    );
}