import './inputDisplay.css';

export default function InputDisplay({ label, value, ...rest }) {

    return (
        <div class="single-input">
            <input type="text" className="input" value={value} disabled {...rest}/>
            <label>{label}</label>
        </div>
    );
}