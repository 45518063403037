const isPermission = (cod) => {
    const infoLogin = JSON.parse(sessionStorage.getItem('infoLogin'));
    if(infoLogin && infoLogin.permissoes){
        const permissoes = String(infoLogin.permissoes).split(',');
        const permite = permissoes.filter(p => Number(p) === Number(cod));
        if(permite.length === 1) return true;
    }
    return false;
}

export const allPermissions = [
    { id: 1, label: 'PARCERIAS', value: false, route: '/parcerias' },
    { id: 3, label: 'USUARIOS', value: false, route: '/usuarios' },
    { id: 9, label: 'CADASTRO USUARIOS', value: false, route: '/cadastrousuario' },
    { id: 2, label: 'ACESSAR USUARIO', value: false },
    { id: 4, label: 'CONTADORES', value: false, route: '/contabilidades' },
    { id: 5, label: 'CADASTRAR CONTADORES', value: false, route: '/cadastrocontador' },
    { id: 7, label: 'CLIENTES', value: false, route: '/clientes' },
    { id: 10, label: 'CADASTRO CLIENTES', value: false, route: '/cadastrocliente' },
    { id: 8, label: 'PRODUTOS', value: false, route: '/produtos' },
    { id: 11, label: 'CADASTRAR/EDITAR PRODUTOS', value: false },
    { id: 23, label: 'ALTERAR CUSTO PRODUTOS', value: false },
    { id: 12, label: 'ALTERAR PREÇO PRODUTOS', value: false },
    { id: 13, label: 'CERTIFICADOS', value: false, route: '/certificados' },
    { id: 14, label: 'FAZER PEDIDO DE CERTIFICADO', value: false, route: '/novopedido' },
    { id: 15, label: 'ALTERAR PREÇO DO PEDIDO', value: false },
    { id: 20, label: 'RELATORIOS DE CERTIFICADOS', value: false },
    { id: 21, label: 'RELATORIOS GERAL', value: false },
    { id: 22, label: 'RECIBO AVULSO', value: false },
];

export default isPermission;