import React from 'react';
import { Link } from 'react-router-dom';
import { FaTachometerAlt, FaIdCard, FaHandsHelping } from 'react-icons/fa';
import { FaUsers, FaUserLock, FaUserTie, FaFileImport } from 'react-icons/fa';
import Typography from '@mui/material/Typography';
import isPermission from '../../services/permission';

import './style.css';

const SideBar = ({ menuActive }) => {

    const MenuItem = ({ menu = null, sub = '', IconMenu, label = '', itemSubMenu = null, ...rest }) => {
        const active = (menuActive === menu) ? 'menu-active' : '';

        return (
            <div className={`menu-item-sidebar ${sub} ${active}`} {...rest}>
                <div className="link-menu-sidebar">
                    <IconMenu />
                    <Typography component="h6">{label}</Typography>
                </div>
            </div>
        );
    }

    return (
        <div className="side-area">
            <div className="header-menu" />
            <Link to="/">
                <MenuItem
                    menu="home"
                    IconMenu={FaTachometerAlt}
                    label="INICIO"
                />
            </Link>

            {isPermission(1) && (
                <Link to="/parcerias">
                    <MenuItem
                        menu="parcerias"
                        IconMenu={FaHandsHelping}
                        label="PARCERIAS"
                    />
                </Link>
            )}

            {isPermission(3) && (
                <Link to="/usuarios">
                    <MenuItem
                        menu="usuarios"
                        IconMenu={FaUserLock}
                        label="USUARIOS"
                    />
                </Link>
            )}

            {isPermission(4) && (
                <Link to="/contabilidades">
                    <MenuItem
                        menu="contabilidade"
                        IconMenu={FaUserTie}
                        label="CONTABILIDADES"
                    />
                </Link>
            )}

            {isPermission(7) && (
                <Link to="/clientes">
                    <MenuItem
                        menu="clientes"
                        IconMenu={FaUsers}
                        label="CLIENTES"
                    />
                </Link>
            )}
            {isPermission(8) && (
                <Link to="/produtos">
                    <MenuItem
                        menu="produtos"
                        IconMenu={FaIdCard}
                        label="PRODUTOS"
                    />
                </Link>
            )}
            {isPermission(13) && (
                <Link to="/certificados">
                    <MenuItem
                        menu="certificados"
                        IconMenu={FaFileImport}
                        label="CERTIFICADOS"
                    />
                </Link>
            )}
        </div>
    );
}

export default SideBar;