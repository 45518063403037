import { useState, useEffect } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Template from "../../template/Template";
import SimpleBackdrop from "../../components/Loading/SimpleBackdrop";
import Input from "../../components/Inputs/Input";
import SelectSimple from '../../components/Selects/SelectSimple';
import SelectCity from "../../components/Selects/SelectCity";
import { AlertConfirm, AlertInfo } from "../../components/Alerts/Alert";
import { api } from "../../services/api";
import { arrayUf } from "../../data/uf";

export default function CadastroContador() {
    const navigate = useNavigate();
    const [searchParms] = useSearchParams();
    const [infoLogin] = useState(JSON.parse(sessionStorage.getItem('infoLogin')));
    const [openLoad, setOpenLoad] = useState(false);
    const [id_contador, setIdContador] = useState("");
    const [nome_contador, setNomeContador] = useState("");
    const [cpf_contador, setCpfContador] = useState("");
    const [empresa_contador, setEmpresaContador] = useState("");
    const [cnpj_contador, setCnpjContador] = useState("");
    const [endereco_contador, setEnderecoContador] = useState("");
    const [numero_contador, setNumeroContador] = useState("");
    const [bairro_contador, setBairroContador] = useState("");
    const [complemento_contador, setComplementoContador] = useState("");
    const [cidade_contador, setCidadeContador] = useState("");
    const [uf_contador, setUfContador] = useState("PA");
    const [cep_contador, setCepContador] = useState("");
    const [telefone_contador, setTelefoneContador] = useState("");
    const [celular_contador, setCelularContador] = useState("");
    const [email_contador, setEmailContador] = useState("");
    const [crc, setCrc] = useState("");
    const [chave_pix_contador, setChavePixContador] = useState("");
    const [user_cad_contador, setUserCad] = useState(infoLogin.id_usuario);

    async function handleSave() {
        setOpenLoad(true);
        try {
            const data = {
                nome_contador, cpf_contador, empresa_contador, cnpj_contador, endereco_contador, numero_contador, bairro_contador, complemento_contador,
                cidade_contador, uf_contador, cep_contador, telefone_contador, celular_contador, email_contador, crc, chave_pix_contador,
                user_cad_contador,
            };
            const channel = id_contador ? `/updatecontador` : `/createcontador`;
            const dataSend = id_contador ? { ...data, id_contador } : data;
            const response = await api.post(channel, dataSend);
            if (response && response.status === 200 && response.data) {
                if (response.data.error) {
                    AlertInfo('error', response.data.message);
                } else {
                    AlertInfo('success', 'DADOS GRAVADOS').then(() => {
                        navigate('/contabilidades');
                    });
                }
            } else {
                AlertInfo('error', 'FALHA NA OPERAÇÃO');
                console.log(response);
            }
        } catch (error) {
            console.log(error);
            AlertInfo('error', 'FALHA NA CONEXÃO');
        }
        setOpenLoad(false);
    }

    function setDataContador(data) {
        if (data && Number(data.user_cad_contador) === Number(infoLogin.id_usuario)) {
            setIdContador(data.id_contador);
            setNomeContador(data.nome_contador);
            setCpfContador(data.cpf_contador);
            setEmpresaContador(data.empresa_contador);
            setCnpjContador(data.cnpj_contador);
            setEnderecoContador(data.endereco_contador);
            setNumeroContador(data.numero_contador);
            setBairroContador(data.bairro_contador);
            setComplementoContador(data.complemento_contador);
            setCidadeContador(data.cidade_contador);
            setUfContador(data.uf_contador);
            setCepContador(data.cep_contador);
            setTelefoneContador(data.telefone_contador);
            setCelularContador(data.celular_contador);
            setEmailContador(data.email_contador);
            setCrc(data.crc);
            setChavePixContador(data.chave_pix_contador);
            setUserCad(data.user_cad_contador);
        }
    }

    async function loadData() {
        setOpenLoad(true);
        const id = searchParms.get('id');
        if (id) {
            try {
                const data = { id_contador: id };
                const response = await api.post(`/contador`, data);
                console.log(response);
                if (response && response.status === 200 && response.data) {
                    if (response.data.error) AlertInfo("error", response.data.message);
                    else setDataContador(response.data[0]);
                }
            } catch (error) {
                console.log(error);
                AlertInfo("error", 'NÃO POSSIVEL BUSCAR DADOS');
            }
        }
        setOpenLoad(false);
    }

    async function handleDelete() {
        if(Number(user_cad_contador) === Number(infoLogin.id_usuario)){
            AlertConfirm('', "DELETAR CONTABILIDADE", "", "SIM").then(async res => {
                if(res.isConfirmed){
                    setOpenLoad(true);
                    try {
                        const response = await api.delete(`/deletecontador/${id_contador}`);
                        if(response && response.status === 200 && response.data){
                            if(response.data.success){
                                AlertInfo('success', 'CONTABILIDADE DELETADA').then(() => {
                                    navigate('/contabilidades');
                                });
                            }
                        }
                    } catch (error) {
                        console.log(error);
                        AlertInfo('error', 'FALHA NA CONEXÃO');
                    }
                    setOpenLoad(false);
                }
            });
        }
    }

    useEffect(() => {
        loadData();
        // eslint-disable-next-line
    }, []);

    return (
        <Template menuActive="contabilidade">
            <SimpleBackdrop open={openLoad} />
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <h2 style={{ textAlign: 'center' }}>DADOS CONTABILIDADE</h2>
                </Grid>
                {id_contador && (
                    <>
                        <Grid item xs={12} sm={2}>
                            <Input
                                label="CODIGO"
                                value={id_contador}
                                setValue={() => {}}
                                upper={false}
                            />
                        </Grid>
                        <Grid item xs={false} sm={8}></Grid>
                        <Grid item xs={12} sm={2}>
                            <Button
                                fullWidth
                                variant="contained"
                                onClick={() => handleDelete()}
                            >
                                DELETAR
                            </Button>
                        </Grid>
                    </>
                )}
                <Grid item xs={12} sm={8}>
                    <Input
                        id="nome_contador"
                        label="NOME"
                        value={nome_contador}
                        setValue={setNomeContador}
                        next="cpf_contador"
                        autoFocus
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Input
                        id="cpf_contador"
                        label="CPF"
                        value={cpf_contador}
                        setValue={setCpfContador}
                        next="empresa_contador"
                    />
                </Grid>
                <Grid item xs={12} sm={8}>
                    <Input
                        id="empresa_contador"
                        label="EMPRESA"
                        value={empresa_contador}
                        setValue={setEmpresaContador}
                        next="cnpj_contador"
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Input
                        id="cnpj_contador"
                        label="CNPJ"
                        value={cnpj_contador}
                        setValue={setCnpjContador}
                        next="endereco_contador"
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Input
                        id="endereco_contador"
                        label="ENDEREÇO"
                        value={endereco_contador}
                        setValue={setEnderecoContador}
                        next="numero_contador"
                    />
                </Grid>
                <Grid item xs={12} sm={2}>
                    <Input
                        id="numero_contador"
                        label="NUMERO"
                        value={numero_contador}
                        setValue={setNumeroContador}
                        next="bairro_contador"
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Input
                        id="bairro_contador"
                        label="BAIRRO"
                        value={bairro_contador}
                        setValue={setBairroContador}
                        next="complemento_contador"
                    />
                </Grid>
                <Grid item xs={12} sm={5}>
                    <Input
                        id="complemento_contador"
                        label="COMPLEMENTO"
                        value={complemento_contador}
                        setValue={setComplementoContador}
                        next="cep_contador"
                    />
                </Grid>
                <Grid item xs={12} sm={2}>
                    <Input
                        id="cep_contador"
                        label="CEP"
                        value={cep_contador}
                        setValue={setCepContador}
                    />
                </Grid>
                <Grid item xs={12} sm={1}>
                    <SelectSimple
                        label="UF"
                        value={uf_contador}
                        setValue={setUfContador}
                        options={arrayUf}
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <SelectCity
                        uf={uf_contador}
                        cidade={cidade_contador}
                        setCidade={setCidadeContador}
                    />
                </Grid>
                <Grid item xs={12} sm={3}>
                    <Input
                        id="telefone_contador"
                        label="TELEFONE"
                        value={telefone_contador}
                        setValue={setTelefoneContador}
                        next="celular_contador"
                    />
                </Grid>
                <Grid item xs={12} sm={3}>
                    <Input
                        id="celular_contador"
                        label="CELULAR"
                        value={celular_contador}
                        setValue={setCelularContador}
                        next="email_contador"
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <Input
                        id="email_contador"
                        label="EMAIL"
                        value={email_contador}
                        setValue={setEmailContador}
                        next="crc"
                    />
                </Grid>
                <Grid item xs={12} sm={3}>
                    <Input
                        id="crc"
                        label="CRC"
                        value={crc}
                        setValue={setCrc}
                        next="chave_pix_contador"
                    />
                </Grid>
                <Grid item xs={12} sm={3}>
                    <Input
                        id="chave_pix_contador"
                        label="CHAVE PIX"
                        value={chave_pix_contador}
                        setValue={setChavePixContador}
                    />
                </Grid>
                <Grid item xs={12}>
                    <hr />
                </Grid>
                <Grid item xs={false} sm={4}></Grid>
                <Grid item xs={12} sm={4}>
                    <Button
                        fullWidth
                        variant="contained"
                        onClick={() => handleSave()}
                    >
                        SALVAR
                    </Button>
                </Grid>
                <Grid item xs={false} sm={4}></Grid>
            </Grid>
        </Template>
    );
}