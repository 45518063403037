import { useState, useEffect } from "react";
import moment from "moment";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip } from "recharts";
import Paper from "@mui/material/Paper";
import { GetLabelMonthSub, getRager } from '../../services/date';

export default function GraficoCertificados({ view = true, array = [] }) {
	const [data, setData] = useState([
		{ name: GetLabelMonthSub(5), total: 0, ranger: getRager(moment().subtract(5, 'months')) },
		{ name: GetLabelMonthSub(4), total: 0, ranger: getRager(moment().subtract(4, 'months')) },
		{ name: GetLabelMonthSub(3), total: 0, ranger: getRager(moment().subtract(3, 'months')) },
		{ name: GetLabelMonthSub(2), total: 0, ranger: getRager(moment().subtract(2, 'months')) },
		{ name: GetLabelMonthSub(1), total: 0, ranger: getRager(moment().subtract(1, 'months')) },
		{ name: GetLabelMonthSub(), total: 0, ranger: getRager() },
	]);

	function loadData() {
		if(array.length > 0){
			const order = array.reverse();
			const allData = data.map((item, index) => {
				return { ...item, total: order[index][0].nEmissao }
			});
			setData(allData);
		}
	}

	useEffect(() => {
		loadData();
		// eslint-disable-next-line
	}, [array]);

	return(
		<Paper elevation={3}>
			<div style={{display:'flex', flexDirection:'column', alignItems: 'center'}}>
				<br />
				<BarChart
					width={450}
					height={220}
					data={data}
					margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
					barSize={20}
				>
					<XAxis dataKey="name" scale="point" padding={{ left: 10, right: 10 }} />
					{view && (<YAxis />)}
					{view && (<Tooltip formatter={(value, name) => [`Emissões: ${value}`]} />)}
					<CartesianGrid strokeDasharray="3 3" />
					<Bar dataKey="total" fill="#a71a1a" background={{ fill: "#ddd" }} />
				</BarChart>
				<br />
			</div>
		</Paper>
  	);
}
