import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Template from "../../template/Template";
import TableDataSimple from '../../components/Tables/TableDataSimple';
import SimpleBackdrop from "../../components/Loading/SimpleBackdrop";
import { AlertInfo } from "../../components/Alerts/Alert";
import { api } from "../../services/api";

export default function Parcerias() {
    const [openLoad, setOpenLoad] = useState(false);
    const [rows, setRows] = useState([]);
    const navigate = useNavigate();
    const columns = [
        {
            id: 'id_empresa',
            numeric: false,
            label: 'CODIGO',
        },
        {
            id: 'fantasia_empresa',
            numeric: false,
            disablePadding: false,
            label: 'NOME',
        },
        {
            id: 'cpfcnpj_empresa',
            numeric: false,
            disablePadding: false,
            label: 'CPF/CNPJ',
        },
        {
            id: 'cidade_empresa',
            numeric: false,
            disablePadding: false,
            label: 'CIDADE',
        },
    ];

    function handleClick(row) {
        navigate(`/cadastroparceria?id=${row.id_empresa}`);
    }

    async function loadData() {
        setOpenLoad(true);
        try {
            const response = await api.get(`/empresas`);
            if(response && response.status === 200 && response.data){
                if(response.data.error) AlertInfo("error", response.data.message);
                else setRows(response.data);
            }
        } catch (error) {
            console.log(error);
            AlertInfo("error", 'NÃO POSSIVEL BUSCAR DADOS');
        }
        setOpenLoad(false);
    }

    useEffect(() => {
        loadData();
        // eslint-disable-next-line
    }, []);

    return (
        <Template menuActive="parcerias">
            <SimpleBackdrop open={openLoad} />
            <Grid container spacing={1}>
                <Grid item xs={12} sm={2}>
                    <Link to="/cadastroparceria">
                        <Button
                            variant="contained"
                            fullWidth
                        >
                            NOVO
                        </Button>
                    </Link>
                </Grid>
                <Grid item xs={12}>
                    <h3>Lista de Parceiros</h3>
                </Grid>
                <Grid item xs={12}>
                    <TableDataSimple
                        columns={columns}
                        rows={rows}
                        handleClick={handleClick}
                    />
                </Grid>
            </Grid>
        </Template>
    );
}