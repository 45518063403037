import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Template from "../../template/Template";
import TableDataSimple from '../../components/Tables/TableDataSimple';
import SimpleBackdrop from "../../components/Loading/SimpleBackdrop";
import { AlertInfo } from "../../components/Alerts/Alert";
import { api } from "../../services/api";
import isPermission from "../../services/permission";

export default function Usuarios() {
    const [openLoad, setOpenLoad] = useState(false);
    const [rows, setRows] = useState([]);
    const navigate = useNavigate();
    const columns = [
        {
            id: 'id_usuario',
            numeric: false,
            label: 'CODIGO',
        },
        {
            id: 'alias',
            numeric: false,
            label: 'NOME USUARIO',
        },
        {
            id: 'fantasia_empresa',
            numeric: false,
            label: 'EMPRESA PARCEIRA',
        }
    ];

    function handleClick(row) {
        navigate(`/cadastrousuario?id=${row.id_usuario}`);
    }

    async function loadData() {
        setOpenLoad(true);
        try {
            const response = await api.get(`/usuarios`);
            if(response && response.status === 200 && response.data){
                if(response.data.error) AlertInfo("error", response.data.message);
                else setRows(response.data);
            }
        } catch (error) {
            console.log(error);
            AlertInfo("error", 'NÃO POSSIVEL BUSCAR DADOS');
        }
        setOpenLoad(false);
    }

    useEffect(() => {
        loadData();
        // eslint-disable-next-line
    }, []);

    return (
        <Template menuActive="usuarios">
            <SimpleBackdrop open={openLoad} />
            <Grid container spacing={1}>
                {isPermission(9) && (
                    <Grid item xs={12} sm={2}>
                        <Link to="/cadastrousuario">
                            <Button
                                variant="contained"
                                fullWidth
                            >
                                NOVO
                            </Button>
                        </Link>
                    </Grid>
                )}
                <Grid item xs={12}>
                    <h3>Lista de Usuarios</h3>
                </Grid>
                <Grid item xs={12}>
                    <TableDataSimple
                        columns={columns}
                        rows={rows}
                        handleClick={handleClick}
                    />
                </Grid>
            </Grid>
        </Template>
    );
}