import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FaCalendarTimes, FaCalendarDay, FaCheckSquare, FaClock } from "react-icons/fa";
import moment from "moment";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Template from "../../template/Template";
import CardDestakIcon from "../../components/Cards/CardDestakIcon";
import SimpleBackdrop from "../../components/Loading/SimpleBackdrop";
import { api } from "../../services/api";
import { dateBrl, dateBrlExat } from "../../services/date";
import isPermission from "../../services/permission";

import ModalCertificado from "../Certificados/ModalCertificado";
import GraficoCertificados from "./GraficoCertificados";
import PaperList from "./PaperList";

export default function Home() {
    const [infoLogin] = useState(JSON.parse(sessionStorage.getItem('infoLogin')));
    const [openLoad, setOpenLoad] = useState(false);
    const [Avencer, setAvencer] = useState([]);
    const [agendamentos, setAgendamentos] = useState([]);
    const [subAgendamentos, setSubAgendamentos] = useState([]);
    const [emitidos, setEmitidos] = useState([]);
    const [expirando, setExpirando] = useState([]);
    const [data, setData] = useState([]);
    const [openModalCertificado, setOpenModalCertificado] = useState(false);
    const [infoModalCertificado, setInfoModalCertificado] = useState(null);
    const [anchorEl, setAnchorEl] = useState(null);
    const navigate = useNavigate();
    const open = Boolean(anchorEl);

    async function loadData() {
        setOpenLoad(true);
        try {
            const response = await api.get(`/home`);
            if (response && response.status === 200 && response.data) {
                setAvencer(response.data.Avencer);
                setAgendamentos(response.data.agendamentos);
                setEmitidos(response.data.emitidos);
                setExpirando(response.data.expirando);
                setData(response.data.data);
                setSubAgendamentos(response.data.subAgendamentos);
            }
        } catch (error) {
            console.log(error);
        }
        setOpenLoad(false);
    }

    function openCertificadoExpired(item, event) {
        setInfoModalCertificado(item);
        setAnchorEl(event.currentTarget);
    }

    function openAgendamento(agendamento) {
        setAnchorEl(null);
        if (isPermission(13)) {
            setInfoModalCertificado(agendamento);
            setOpenModalCertificado(true);
        }
    }

    function isColorAgendamento(item) {
        if(Number(item.id_empresa_certificado) === Number(infoLogin.id_empresa_usuario)) return "#000";
        return "#1500ff";
    }
    
    function isColorVencimento(item) {
        if(emitidos.length > 0){
            const isEmited = emitidos.filter(i => i.cpfcnpj === item.cpfcnpj);
            if(isEmited.length > 0) return "#6bbd5b";
        }
        return "#a71a1a";
    }

    useEffect(() => {
        if(Avencer.length > 4){
            const isNow = Avencer.filter(i => !moment(i.validade_fim).isBefore(moment()));
            if(isNow.length > 0){
                let isView = document.getElementById(isNow[0].id_certificado);
                if(isView) isView.scrollIntoView();
            }
        }
    }, [Avencer]);

    useEffect(() => {
        loadData();
    }, []);

    return (
        <Template menuActive="home">
            <SimpleBackdrop open={openLoad} />
            <ModalCertificado
                open={openModalCertificado}
                setOpen={setOpenModalCertificado}
                info={infoModalCertificado}
                loadData={loadData}
            />
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={() => setAnchorEl(null)}
            >
                <MenuItem onClick={() => navigate(`/novopedido?id=${infoModalCertificado.cpfcnpj}`)}>RENOVAR CERTIFICADO</MenuItem>
                <MenuItem onClick={() => navigate(`/cadastrocliente?id=${infoModalCertificado.cliente_id}`)}>CADASTRO CLIENTE</MenuItem>
                <MenuItem onClick={() => openAgendamento(infoModalCertificado)}>INFORMAÇÕES DO VENCIMENTO</MenuItem>
            </Menu>

            <Grid container spacing={1}>
                <Grid item xs={12} sm={3}>
                    <CardDestakIcon
                        icon={FaCheckSquare}
                        colorIcon='#6bbd5b'
                        title='CERTIFICADOS EMITIDOS'
                        subTitle={emitidos.length}
                    />
                </Grid>
                <Grid item xs={12} sm={3}>
                    <CardDestakIcon
                        icon={FaClock}
                        colorIcon='#000'
                        title='PENDENTES'
                        subTitle={agendamentos.length}
                    />
                </Grid>
                <Grid item xs={12} sm={3}>
                    <CardDestakIcon
                        icon={FaCalendarTimes}
                        colorIcon='#a71a1a'
                        title='EXPIRANDO ESTE MÊS'
                        subTitle={expirando.length}
                    />
                </Grid>
                <Grid item xs={false} sm={12}></Grid>
                <Grid item xs={12} sm={4}>
                    <Button
                        variant="contained"
                        color="primary"
                        style={{ marginBottom: '-10px', marginLeft: '10px', zIndex: 111 }}
                    >
                        RESUMO DE EMISSÕES
                    </Button>
                    <GraficoCertificados array={data} />
                </Grid>

                <Grid item xs={12} sm={4}>
                    <PaperList
                        title="AGENDAMENTOS"
                        array={[...agendamentos, ...subAgendamentos]}
                        color={isColorAgendamento}
                        buttonColor="secondary"
                        columns={{ primary: 'nome', secondary: 'descricao', date: 'data_agendamento' }}
                        emptyText="NENHUM AGENDAMENTO MARCADO"
                        icon={FaCalendarDay}
                        format={(i) => `${dateBrlExat(i.data_agendamento)} - ${i.fantasia_empresa}`}
                        clickFunc={openAgendamento}
                    />
                </Grid>

                <Grid item xs={12} sm={4}>
                    <PaperList
                        title="A VENCER NOS PROXIMOS 7 DIAS"
                        array={Avencer}
                        color={isColorVencimento}
                        columns={{ primary: 'nome', secondary: 'descricao', date: 'validade_fim' }}
                        emptyText="NENHUM CERTIFICADO A VENCER"
                        icon={FaCalendarTimes}
                        format={(i) => dateBrl(i.validade_fim)}
                        clickFunc={openCertificadoExpired}
                    />
                </Grid>
            </Grid>
        </Template>
    );
}