import { useState, Fragment } from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';

export default function Setup({ steps, stepsContent, communStates }) {
    const [activeStep, setActiveStep] = useState(0);

    function getStep() {
        const StepComponent = stepsContent[activeStep] ? stepsContent[activeStep] : () => {};
        return(
            <StepComponent setActiveStep={setActiveStep} ButtonActions={ButtonActions} communStates={communStates} />
        );
    }

    const ButtonActions = ({ handleBack, handleNext, isFinal = false }) => {
        return(
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                <Button
                    color="inherit"
                    variant="contained"
                    disabled={activeStep === 0}
                    onClick={handleBack}
                    sx={{ mr: 1 }}
                >
                    VOLTAR
                </Button>
                <Box sx={{ flex: '1 1 auto' }} />

                <Button
                    variant="contained"
                    onClick={handleNext}
                >
                    {isFinal ? 'FINALIZAR': 'PROXIMO'}
                </Button>
            </Box>
        );
    }

    return (
        <Box sx={{ width: '100%' }}>
            <Stepper activeStep={activeStep}>
                {steps.map(label => (
                    <Step key={label}>
                        <StepLabel>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
            <Fragment>
                {getStep()}
            </Fragment>
        </Box>
    );
}
