import SideBar from './SideBar/SideBar';
import TopBar from './TopBar/TopBar';
import './style.css';

export default function Template({children, menuActive}) {
    return(
        <>
            <SideBar 
                menuActive={menuActive}
            />
            <div className="container-template">
                <TopBar />
                <div className="view-template">
                    { children }
                </div>
            </div>
        </>
    );
}