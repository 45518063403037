import { useState, useEffect } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Template from "../../template/Template";
import SimpleBackdrop from "../../components/Loading/SimpleBackdrop";
import Input from "../../components/Inputs/Input";
import SelectSimple from '../../components/Selects/SelectSimple';
import { AlertInfo } from "../../components/Alerts/Alert";
import { api } from "../../services/api";
import isPermission, { allPermissions } from "../../services/permission";

export default function CadastroUsuario() {
    const navigate = useNavigate();
    const [searchParms] = useSearchParams();
    const [infoLogin] = useState(JSON.parse(sessionStorage.getItem('infoLogin')));
    const [openLoad, setOpenLoad] = useState(false);

    const [id_usuario, setIdUsuario] = useState(null);
    const [is_agr, setIsAgr] = useState(false);
    const [id_empresa_usuario, setIdEmpresaUsuario] = useState(0);
    const [nome_completo, setNomeCompleto] = useState("");
    const [alias, setAlias] = useState("");
    const [cpf, setCpf] = useState("");
    const [telefone_usuario, setTelefoneUsuario] = useState("");
    const [email_usuario, setEmailUsuario] = useState("");
    const [senha, setSenha] = useState("");
    const [atualSenha, setAtualSenha] = useState("");
    const [arrayEmpresa, setArrayEmpresa] = useState([]);

    const [allPermissoes, setAllPermissoes] = useState(allPermissions);
    const [divGrid] = useState(Math.ceil(allPermissoes.length / 3));

    async function handleSave() {
        setOpenLoad(true);
        try {
            const data = {
                id_usuario, is_agr: Number(is_agr), id_empresa_usuario,
                nome_completo, alias, cpf,
                telefone_usuario, email_usuario, senha,
                permissoes: allPermissoes.filter(i => i.value).map(i => i.id).toString()
            };
            const channel = id_usuario ? `/updateusuario` : `/createusuario`;
            const response = await api.post(channel, data);
            if (response && response.status === 200 && response.data) {
                if (response.data.error) {
                    AlertInfo('error', response.data.message);
                } else {
                    AlertInfo('success', 'DADOS GRAVADOS').then(() => {
                        navigate('/usuarios');
                    });
                }
            } else {
                AlertInfo('error', 'FALHA NA OPERAÇÃO');
                console.log(response);
            }
        } catch (error) {
            console.log(error);
            AlertInfo('error', 'FALHA NA CONEXÃO');
        }
        setOpenLoad(false);
    }

    function setPermissoes(array) {
        const up = allPermissoes.map(i => {
            if(array.includes(String(i.id))) return {...i, value: true}
            return i;
        });
        setAllPermissoes(up);
    }

    function setDataUsuario(data) {
        if (data && data.id_usuario) {
            setIdUsuario(data.id_usuario);
            setIsAgr(Boolean(Number(data.is_agr)));
            setIdEmpresaUsuario(data.id_empresa_usuario);
            setNomeCompleto(data.nome_completo);
            setAlias(data.alias);
            setCpf(data.cpf);
            setTelefoneUsuario(data.telefone_usuario);
            setEmailUsuario(data.email_usuario);
            setPermissoes(String(data.permissoes).split(','));
            setAtualSenha(data.senha);
        }
    }

    async function loadData() {
        setOpenLoad(true);
        const id = searchParms.get('id');
        if (id) {
            try {
                const data = { id_usuario: id };
                const response = await api.post(`/usuario`, data);
                console.log(response);
                if (response && response.status === 200 && response.data) {
                    if (response.data.error) AlertInfo("error", response.data.message);
                    else setDataUsuario(response.data[0]);
                }
            } catch (error) {
                console.log(error);
                AlertInfo("error", 'NÃO POSSIVEL BUSCAR DADOS');
            }
        }
        try {
            const getEmpresas = await api.get(`empresas`);
            if (getEmpresas && getEmpresas.status === 200 && getEmpresas.data) {
                if (!getEmpresas.data.error) {
                    const data = getEmpresas.data;
                    const formatData = data.map(i => ({ ...i, value: i.id_empresa, label: i.fantasia_empresa }));
                    setArrayEmpresa([{ value: 0, label: "---" }, ...formatData]);
                }
            }
        } catch (error) {
            console.log(error);
        }
        setOpenLoad(false);
    }

    function handleCheckPermission(id) {
        const up = allPermissoes.map(i => {
            if(i.id === id) return {...i, value: !i.value}
            return i;
        });
        setAllPermissoes(up);
    }

    async function handleSubLogin() {
        setOpenLoad(true);
        try {
            const data = { id_usuario, senha: atualSenha };
            const response = await api.post(`/sublogin`, data);
            if(response && response.status && response.data){
                if(response.data.error){
                    AlertInfo('error', response.data.message);
                }else{
                    sessionStorage.setItem('masterLogin', JSON.stringify(infoLogin));
                    sessionStorage.setItem('infoLogin', JSON.stringify(response.data));
                    setOpenLoad(false);
                    navigate('/certificados');
                }
            }
        } catch (error) {
            console.log(error);
            AlertInfo('error', 'FALHA NA CONEXÃO');
        }
        setOpenLoad(false);
    }

    useEffect(() => {
        loadData();
        // eslint-disable-next-line
    }, []);

    return (
        <Template menuActive="usuarios">
            <SimpleBackdrop open={openLoad} />
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <h2 style={{ textAlign: 'center' }}>DADOS USUARIOS</h2>
                </Grid>
                {isPermission(2) && id_usuario && (
                    <Grid item xs={12} sm={2}>
                        <Button
                            fullWidth
                            variant="contained"
                            onClick={() => handleSubLogin()}
                        >
                            ACESSAR
                        </Button>
                    </Grid>
                )}
                <Grid item xs={false} sm={12}></Grid>
                <Grid item xs={12} sm={6}>
                    <Input
                        id="nome_completo"
                        label="NOME COMPLETO"
                        value={nome_completo}
                        setValue={setNomeCompleto}
                        next="alias"
                        autoFocus
                    />
                </Grid>
                <Grid item xs={12} sm={3}>
                    <Input
                        id="alias"
                        label="NOME ABREVIADO"
                        value={alias}
                        setValue={setAlias}
                        next="cpf"
                    />
                </Grid>
                <Grid item xs={12} sm={3}>
                    <Input
                        id="cpf"
                        label="CPF"
                        value={cpf}
                        setValue={setCpf}
                        mask="cpf"
                        next="telefone_usuario"
                    />
                </Grid>

                <Grid item xs={12} sm={2}>
                    <Input
                        id="telefone_usuario"
                        label="TELEFONE"
                        value={telefone_usuario}
                        setValue={setTelefoneUsuario}
                        mask="telefone"
                        next="email_usuario"
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Input
                        id="email_usuario"
                        label="EMAIL"
                        value={email_usuario}
                        setValue={setEmailUsuario}
                        next="senha"
                    />
                </Grid>
                <Grid item xs={12} sm={3}>
                    <Input
                        id="senha"
                        label="SENHA"
                        value={senha}
                        setValue={setSenha}
                    />
                </Grid>
                <Grid item xs={12} sm={3}>
                    <SelectSimple
                        label="PARCERIA"
                        value={id_empresa_usuario}
                        setValue={setIdEmpresaUsuario}
                        options={arrayEmpresa}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormControlLabel
                        control={
                            <Checkbox checked={is_agr} onChange={() => setIsAgr(!is_agr)} />
                        }
                        label="AGR HABILITADO"
                    />
                </Grid>
                <Grid item xs={12}>
                    <hr />
                </Grid>
                {[1, 2, 3].map((arr, index) => (
                    <Grid item xs={12} sm={4}>
                        <FormGroup>
                            {allPermissoes.slice((divGrid * index), (divGrid * arr)).map(i => (
                                <FormControlLabel
                                    key={`permissao${i.id}`}
                                    control={
                                        <Checkbox
                                            checked={i.value}
                                            onChange={() => handleCheckPermission(i.id)}
                                            style={{ padding: '2px' }}
                                        />
                                    }
                                    label={i.label}
                                />
                            ))}
                        </FormGroup>
                    </Grid>
                ))}
                <Grid item xs={12}>
                    <hr />
                </Grid>
                <Grid item xs={false} sm={4}></Grid>
                <Grid item xs={12} sm={4}>
                    <Button
                        fullWidth
                        variant="contained"
                        onClick={() => handleSave()}
                    >
                        SALVAR
                    </Button>
                </Grid>
                <Grid item xs={false} sm={4}></Grid>
            </Grid>
        </Template>
    );
}