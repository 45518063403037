import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import isPermission, { allPermissions } from './services/permission';

import Login from './pages/Login/Login';
import Home from './pages/Home/Home';
import Parcerias from './pages/Parcerias/Parcerias';
import CadastroParceiro from './pages/Parcerias/CadastroParceria';
import Usuarios from './pages/Usuarios/Usuarios';
import CadastroUsuario from './pages/Usuarios/CadastroUsuario';
import Contabilidades from './pages/Contablidades/Contabilidades';
import CadastroContador from './pages/Contablidades/CadastroContador';
import Clientes from './pages/Clientes/Clientes';
import CadastroCliente from './pages/Clientes/CadastroCliente';
import Produtos from './pages/Produtos/Produtos';
import Certificados from './pages/Certificados/Certificados';
import NovoPedido from './pages/Certificados/NovoPedido';

const isAuthentication = (path) => {
    const infoLogin = sessionStorage.getItem('infoLogin') || null;
    if(infoLogin){
        if(path){
            const getPermission = allPermissions.filter(i => i.route === path);
            if(getPermission.length > 0){
                if(isPermission(getPermission[0].id)) return true;
            }
            return false;
        }
        return true;
    }else{
        return false;
    }
};

const PrivateRoute = (props) => {
    const { component: Component, path = null } = props;
    return isAuthentication(path) ? <Component/> : <Navigate replace to={"/login"} />
};

export default function MyRoutes() {
    return(
        <BrowserRouter>
            <Routes>
                <Route path="/login" element={<Login />} />
                
                <Route path="/" element={<PrivateRoute component={Home} />} />
                <Route path="/parcerias" element={<PrivateRoute component={Parcerias} path="/parcerias" />} />
                <Route path="/cadastroparceria" element={<PrivateRoute component={CadastroParceiro} />} />
                <Route path="/usuarios" element={<PrivateRoute component={Usuarios} path="/usuarios" />} />
                <Route path="/cadastrousuario" element={<PrivateRoute component={CadastroUsuario} path="/cadastrousuario" />} />
                <Route path="/contabilidades" element={<PrivateRoute component={Contabilidades} path="/contabilidades" />} />
                <Route path="/cadastrocontador" element={<PrivateRoute component={CadastroContador} path="/cadastrocontador" />} />
                <Route path="/clientes" element={<PrivateRoute component={Clientes} path="/clientes" />} />
                <Route path="/cadastrocliente" element={<PrivateRoute component={CadastroCliente} path="/cadastrocliente" />} />
                <Route path="/produtos" element={<PrivateRoute component={Produtos} path="/produtos" />} />
                <Route path="/certificados" element={<PrivateRoute component={Certificados} path="/certificados" />} />
                <Route path="/novopedido" element={<PrivateRoute component={NovoPedido} path="/novopedido" />} />
            </Routes>
        </BrowserRouter>
    );
}