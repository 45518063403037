import { FormControl, InputLabel } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

export default function SelectSimple({ label, value, setValue, options }) {
    return (
        <FormControl fullWidth>
            <InputLabel>{label}</InputLabel>
            <Select
                value={value}
                label={label}
                onChange={e => setValue(e.target.value)}
                size='small'
                fullWidth
            >
                {options.map(opt => (
                    <MenuItem key={`opt${opt.value}`} value={opt.value}>{opt.label}</MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}
