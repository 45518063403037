import axios from "axios";

// export const apiUrl = 'http://localhost:9000';
export const apiUrl = 'https://apiarrenove.renovetecnologia.com';
export const asaasUrl = `https://connectasaas.renovetecnologia.com`;
export const urlCalbackSafe2Pay = `${apiUrl}/notifyApiSafe2Pay`;
// export const asaasUrl = `http://localhost:4004`;

export const api = axios.create({
    baseURL: apiUrl,
});

api.interceptors.request.use(config => {
    const infoLogin = JSON.parse(sessionStorage.getItem("infoLogin"));
    config.headers.token = (infoLogin && infoLogin.token) ? infoLogin.token : '';
    return config;
});

export const asaas = axios.create({
    baseURL: asaasUrl
});

export const apisafe2pay = axios.create({
    baseURL: 'https://apinode.renovetecnologia.com',
});