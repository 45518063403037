import { useState } from "react";
import moment from "moment";
import { ListItem } from "@mui/material";
import Button from '@mui/material/Button';
import DrawerApp from "../../components/Drawer/DrawerApp";
import SelectSimple from '../../components/Selects/SelectSimple';
import Input from '../../components/Inputs/Input';
import SimpleBackdrop from "../../components/Loading/SimpleBackdrop";
import { startMes, endMes } from "../../services/date";
import { api } from '../../services/api';
import { AlertInfo } from "../../components/Alerts/Alert";
import { statusCertificado, formasPagamento } from "../../data/options";
 
export default function DrawerFiltroCertficados({ open, setOpen, setCertificados }) {
    const [infoLogin] = useState(JSON.parse(sessionStorage.getItem('infoLogin')));
    const [openLoad, setOpenLoad] = useState(false);
    const [busca, setBusca] = useState("1");
    const [mes, setMes] = useState(moment().format('YYYY-MM'));
    const [dataInicio, setDataInicio] = useState("");
    const [dataFinal, setDataFinal] = useState("");
    const [name, setName] = useState("");
    const [numero_referencia, setNumeroReferencia] = useState("");
    const [situacao, setSituacao] = useState("TODOS");
    const [pagamento, setPagamento] = useState("TODOS");

    function handleSearch() {
        if (["1", "3"].includes(busca)) loadCertificadosByPeriodo();
        else if (["2", "4"].includes(busca)) loadCertificadosByPeriodo('validade_fim');
        else if (busca === "5") loadCertificadosByDataCliente();
        else if (busca === "6") loadCertificadosBySearch();
    }

    async function loadCertificadosByPeriodo(column = 'data_pedido') {
        setOpenLoad(true);
        try {
            const start = ["1", "2"].includes(busca) ? startMes(mes) : dataInicio;
            const end = ["1", "2"].includes(busca) ? endMes(mes) : dataFinal;
            let andExt = {};
            if(pagamento !== "TODOS") andExt = { pagamento };
            if(situacao !== "TODOS") andExt = {...andExt, situacao};
            const data = { id_empresa_certificado: infoLogin.id_empresa_usuario, start, end, column, andExt };
            const response = await api.post(`/certificados`, data);
            if (response && response.status === 200 && response.data) {
                if (response.data.error) AlertInfo("error", response.data.message);
                else setCertificados(response.data);
            }
        } catch (error) {
            console.log(error);
            AlertInfo("error", 'NÃO POSSIVEL BUSCAR DADOS');
        }
        setOpen(false);
        setOpenLoad(false);
    }

    async function loadCertificadosByDataCliente() {
        if (String(name).length > 0) {
            setOpenLoad(true);
            try {
                const data = { id_empresa_certificado: infoLogin.id_empresa_usuario, busca: name };
                const response = await api.post(`/certificadoscliente`, data);
                console.log(response);
                if (response && response.status === 200 && response.data) {
                    if (response.data.error) AlertInfo("error", response.data.message);
                    else setCertificados(response.data);
                }
            } catch (error) {
                console.log(error);
                AlertInfo("error", 'NÃO POSSIVEL BUSCAR DADOS');
            }
            setOpen(false);
            setOpenLoad(false);
        }
    }

    async function loadCertificadosBySearch() {
        if (String(numero_referencia).length > 0) {
            setOpenLoad(true);
            try {
                const data = { numero_referencia };
                const response = await api.post(`/certificadosbysearch`, data);
                console.log(response);
                if (response && response.status === 200 && response.data) {
                    if (response.data.error) AlertInfo("error", response.data.message);
                    else setCertificados(response.data);
                }
            } catch (error) {
                console.log(error);
                AlertInfo("error", 'NÃO POSSIVEL BUSCAR DADOS');
            }
            setOpen(false);
            setOpenLoad(false);
        }
    }

    return (
        <DrawerApp
            open={open}
            setOpen={setOpen}
            title="FILTROS"
        >
            <SimpleBackdrop open={openLoad} />
            <ListItem>
                <SelectSimple
                    label="BUSCA"
                    value={busca}
                    setValue={setBusca}
                    options={[
                        { value: "1", label: 'MÊS PEDIDO' },
                        { value: "2", label: 'MÊS VENCIMENTO' },
                        { value: "3", label: 'PERIODO PEDIDO' },
                        { value: "4", label: 'PERIODO VENCIMENTO' },
                        { value: "5", label: 'CLIENTE' },
                        { value: "6", label: 'CODIGO PEDIDO' },
                    ]}
                />
            </ListItem>

            {["1", "2"].includes(busca) && (
                <ListItem>
                    <Input
                        type="month"
                        label="MÊS"
                        value={mes}
                        upper={false}
                        setValue={setMes}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                </ListItem>
            )}
            {["3", "4"].includes(busca) && (
                <>
                    <ListItem>
                        <Input
                            type="date"
                            label="DATA INCIO"
                            value={dataInicio}
                            setValue={setDataInicio}
                            upper={false}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </ListItem>
                    <ListItem>
                        <Input
                            type="date"
                            label="DATA FINAL"
                            value={dataFinal}
                            upper={false}
                            setValue={setDataFinal}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </ListItem>
                </>
            )}
            {busca === "5" && (
                <ListItem>
                    <Input
                        label="NOME/CPF/CNPJ"
                        value={name}
                        setValue={setName}
                        onKeyUp={e => {
                            if (e.key === 'Enter') handleSearch();
                        }}
                    />
                </ListItem>
            )}
            {busca === "6" && (
                <ListItem>
                    <Input
                        label="CODIGO PEDIDO"
                        value={numero_referencia}
                        setValue={setNumeroReferencia}
                        onKeyUp={e => {
                            if (e.key === 'Enter') handleSearch();
                        }}
                    />
                </ListItem>
            )}

            <ListItem>
                <SelectSimple
                    label="STATUS CERTIFICADO"
                    value={situacao}
                    setValue={setSituacao}
                    options={[
                        { value: "TODOS", label: 'TODOS' },
                        ...statusCertificado
                    ]}
                />
            </ListItem>
            <ListItem>
                <SelectSimple
                    label="FORMA DE PAGAMENTO"
                    value={pagamento}
                    setValue={setPagamento}
                    options={[
                        { value: "TODOS", label: 'TODAS' },
                        ...formasPagamento
                    ]}
                />
            </ListItem>

            <ListItem>
                <Button
                    variant="contained"
                    fullWidth
                    onClick={() => handleSearch()}
                >
                    BUSCAR
                </Button>
            </ListItem>
        </DrawerApp>
    );
}