import { useState, useEffect } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Template from "../../template/Template";
import SimpleBackdrop from "../../components/Loading/SimpleBackdrop";
import Input from "../../components/Inputs/Input";
import SelectSimple from '../../components/Selects/SelectSimple';
import SelectCity from "../../components/Selects/SelectCity";
import { AlertInfo } from "../../components/Alerts/Alert";
import { api } from "../../services/api";
import { arrayUf } from "../../data/uf";

export default function CadastroParceiro() {
    const navigate = useNavigate();
    const [searchParms] = useSearchParams();
    const [openLoad, setOpenLoad] = useState(false);

    const [id_empresa, setIdEmpresa] = useState(null);
    const [razao_empresa, setRazaoEmpresa] = useState("");
    const [fantasia_empresa, setFantasiaEmpresa] = useState("");
    const [cpfcnpj_empresa, setCpfCnpjEmpresa] = useState("");
    const [endereco_empresa, setEnderecoEmpresa] = useState("");
    const [uf_empresa, setUfEmpresa] = useState("PA");
    const [cidade_empresa, setCidadeEmpresa] = useState("");
    const [telefone_empresa, setTelefoneEmpresa] = useState("");
    const [email_empresa, setEmailEmpresa] = useState("");
    const [site_empresa, setSiteEmpresa] = useState("");
    const [api_safe2pay, setApiSafe2Pay] = useState("");

    async function handleSave() {
        setOpenLoad(true);
        try {
            const data = {
                id_empresa, razao_empresa, fantasia_empresa, cpfcnpj_empresa,
                endereco_empresa, uf_empresa, cidade_empresa, telefone_empresa, email_empresa,
                site_empresa, api_safe2pay
            };
            const channel = id_empresa ? `/updateempresa` : `/createempresa`;
            const response = await api.post(channel, data);
            if (response && response.status === 200 && response.data) {
                if (response.data.error) {
                    AlertInfo('error', response.data.message);
                } else {
                    AlertInfo('success', 'DADOS GRAVADOS').then(() => {
                        navigate('/parcerias');
                    });
                }
            } else {
                AlertInfo('error', 'FALHA NA OPERAÇÃO');
                console.log(response);
            }
        } catch (error) {
            console.log(error);
            AlertInfo('error', 'FALHA NA CONEXÃO');
        }
        setOpenLoad(false);
    }

    function setDataCliente(data) {
        if (data && data.id_empresa) {
            setIdEmpresa(data.id_empresa);
            setRazaoEmpresa(data.razao_empresa);
            setFantasiaEmpresa(data.fantasia_empresa);
            setCpfCnpjEmpresa(data.cpfcnpj_empresa);
            setEnderecoEmpresa(data.endereco_empresa);
            setUfEmpresa(data.uf_empresa);
            setCidadeEmpresa(data.cidade_empresa);
            setTelefoneEmpresa(data.telefone_empresa);
            setEmailEmpresa(data.email_empresa);
            setSiteEmpresa(data.site_empresa);
            setApiSafe2Pay(data.api_safe2pay);
        }
    }

    async function loadData() {
        setOpenLoad(true);
        const id = searchParms.get('id');
        if (id) {
            try {
                const data = { id_empresa: id };
                const response = await api.post(`/empresa`, data);
                console.log(response);
                if (response && response.status === 200 && response.data) {
                    if (response.data.error) AlertInfo("error", response.data.message);
                    else setDataCliente(response.data[0]);
                }
            } catch (error) {
                console.log(error);
                AlertInfo("error", 'NÃO POSSIVEL BUSCAR DADOS');
            }
        }
        setOpenLoad(false);
    }

    useEffect(() => {
        loadData();
        // eslint-disable-next-line
    }, []);

    return (
        <Template menuActive="parcerias">
            <SimpleBackdrop open={openLoad} />
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <h2 style={{ textAlign: 'center' }}>DADOS PARCEIRO</h2>
                </Grid>
                <Grid item xs={false} sm={12}></Grid>
                <Grid item xs={12} sm={4}>
                    <Input
                        id="razao_empresa"
                        label="RAZAO/NOME"
                        value={razao_empresa}
                        setValue={setRazaoEmpresa}
                        next="fantasia_empresa"
                        autoFocus
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Input
                        id="fantasia_empresa"
                        label="FANTASIA"
                        value={fantasia_empresa}
                        setValue={setFantasiaEmpresa}
                        next="cpfcnpj_empresa"
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Input
                        id="cpfcnpj_empresa"
                        label="CPF/CNPJ"
                        value={cpfcnpj_empresa}
                        setValue={setCpfCnpjEmpresa}
                        next="endereco_empresa"
                    />
                </Grid>
                
                <Grid item xs={12} sm={6}>
                    <Input
                        id="endereco_empresa"
                        label="ENDEREÇO"
                        value={endereco_empresa}
                        setValue={setEnderecoEmpresa}
                        next="autocomplete"
                    />
                </Grid>
                
                <Grid item xs={12} sm={1}>
                    <SelectSimple
                        label="UF"
                        value={uf_empresa}
                        setValue={setUfEmpresa}
                        options={arrayUf}
                    />
                </Grid>
                <Grid item xs={12} sm={5}>
                    <SelectCity
                        uf={uf_empresa}
                        cidade={cidade_empresa}
                        setCidade={setCidadeEmpresa}
                    />
                </Grid>
                <Grid item xs={12} sm={2}>
                    <Input
                        id="telefone_empresa"
                        label="TELEFONE"
                        value={telefone_empresa}
                        setValue={setTelefoneEmpresa}
                        mask="telefone"
                        next="email_empresa"
                    />
                </Grid>
                <Grid item xs={12} sm={4}>
                    <Input
                        id="email_empresa"
                        label="EMAIL"
                        value={email_empresa}
                        setValue={setEmailEmpresa}
                        next="site_empresa"
                    />
                </Grid>
                <Grid item xs={12} sm={3}>
                    <Input
                        id="site_empresa"
                        label="SITE"
                        value={site_empresa}
                        setValue={setSiteEmpresa}
                        next="api_safe2pay"
                    />
                </Grid>
                <Grid item xs={12} sm={3}>
                    <Input
                        id="api_safe2pay"
                        label="CHAVE API SAFE2PAY"
                        value={api_safe2pay}
                        setValue={setApiSafe2Pay}
                    />
                </Grid>
                <Grid item xs={12}>
                    <hr />
                </Grid>
                <Grid item xs={false} sm={4}></Grid>
                <Grid item xs={12} sm={4}>
                    <Button
                        fullWidth
                        variant="contained"
                        onClick={() => handleSave()}
                    >
                        SALVAR
                    </Button>
                </Grid>
                <Grid item xs={false} sm={4}></Grid>
            </Grid>
        </Template>
    );
}