import { useState } from "react";
import Typography from "@mui/material/Typography";
import Template from "../../template/Template";
import Setup from "../../components/Setup/Setup";

import Pesquisar from "./NovoPedido/Pesquisar";
import DadosCliente from "./NovoPedido/DadosCliente";
import Certificado from "./NovoPedido/Certificado";

export default function NovoPedido() {
    const [infoCliente, setInfoCliente] = useState(null);
    const steps = ['PESQUISAR', 'CLIENTE', 'CERTIFICADO'];
    const stepsContent = [Pesquisar, DadosCliente, Certificado];

    return (
        <Template menuActive="certificados">
            <Typography component="h1" variant="h4" align="center">
                Pedido de Certificado Digital
            </Typography>
            <Setup
                steps={steps}
                stepsContent={stepsContent}
                communStates={{ infoCliente, setInfoCliente }}
            />
        </Template>
    );
}