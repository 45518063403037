import moment from "moment";

export const isDate = (dt = '') => {
    if(moment(dt).isValid()) return dt;
    return null;
}

export const dateEuaExat = (dt = new Date()) => moment(dt).format("YYYY-MM-DD HH:mm:ss");
export const dateEuaHrM = (dt = new Date()) => moment(dt).format("YYYY-MM-DD HH:mm");
export const dateEua = (dt = new Date()) => moment(dt).format("YYYY-MM-DD");

export const dateBrlExat = (dt = new Date()) => moment(dt).format("DD/MM/YYYY HH:mm:ss");
export const dateBrl = (dt = new Date()) => moment(dt).format("DD/MM/YYYY");

export const startMes = (parseData = new Date()) => {
    const data = moment(parseData).startOf('month');
    const dataFormat = dateEua(data);
    return dataFormat;
}

export const endMes = (parseData = new Date()) => {
    const data = moment(parseData).endOf('month');
    const dataFormat = dateEua(data);
    return dataFormat;
}

export const MonthLabel = {
    "01": "JAN",
    "02": "FEV",
    "03": "MAR",
    "04": "ABR",
    "05": "MAI",
    "06": "JUN",
    "07": "JUL",
    "08": "AGO",
    "09": "SET",
    "10": "OUT",
    "11": "NOV",
    "12": "DEZ",
};

export const GetLabelMonthSub = (menos = 0) => {
    const label = MonthLabel[moment().subtract(menos, 'months').format("MM")];
    return label;
}

export const getRager = (dataRefer = new Date()) => {
    const ranger = [startMes(dataRefer), endMes(dataRefer)];
    return ranger;
}