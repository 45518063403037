import { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import SimpleBackdrop from "../../components/Loading/SimpleBackdrop";
import Modal from "../../components/Modal/Modal";
import Input from "../../components/Inputs/Input";
import { dateBrl, dateEua } from "../../services/date";
import { AlertInfo } from "../../components/Alerts/Alert";
import { removeMask } from "../../components/Inputs/mask";
import { api, apiUrl, apisafe2pay, urlCalbackSafe2Pay } from "../../services/api";

export default function ModalCobranca({ open, setOpen, info, pagamento, isLink = false }) {
    const [infoLogin] = useState(JSON.parse(sessionStorage.getItem('infoLogin')));
    const [openLoad, setOpenLoad] = useState(false);
    const [vencimento, setVencimento] = useState(dateEua());
    const [instrucoes, setInstrucoes] = useState("");
    const [linkQrCodePix, setLinkQrCodePix] = useState(null);
    const [linkPixCopiaCola, setPixCopiaCola] = useState(null);

    const paymentObject = () => {
        let payment = {};
        if (pagamento === "1") {
            payment = {
                "DueDate": dateBrl(vencimento),
                "Instruction": instrucoes,
                "Message": [info.descricao],
                "CancelAfterDue": false,
                "IsEnablePartialPayment": false
            }
        }

        return payment;
    }

    async function emitirCobranca() {
        setOpenLoad(true);
        try {
            const dadosCliente = {
                "Name": (Number(info.mesmo_comprador) === 1) ? info.nome : info.nome_comprador,
                "Identity": (Number(info.mesmo_comprador) === 1) ? removeMask(info.cpfcnpj) : removeMask(info.cpfcnpj_comprador),
                "Phone": removeMask(info.telefone),
                "Email": info.email,
                "Address": {
                    "ZipCode": removeMask(info.cep),
                    "Street": info.endereco,
                    "Number": info.numero,
                    "Complement": info.complemento,
                    "District": info.bairro,
                    "CityName": info.cidade,
                    "StateInitials": info.uf,
                    "CountryName": "Brasil"
                }
            };
            const certificado = [{
                "Code": info.id_produto,
                "Description": info.descricao,
                "UnitPrice": info.valor_produto,
                "Quantity": 1
            }];
            const dataSafe2Pay = {
                "IsSandbox": false,
                "Application": "SISTEMA AR RENOVE",
                "Vendor": infoLogin.alias,
                "CallbackUrl": urlCalbackSafe2Pay,
                "PaymentMethod": pagamento,
                "Customer": dadosCliente,
                "Products": certificado,
                "PaymentObject": paymentObject(),
            }
            const response = await apisafe2pay.post(`/Payment`, dataSafe2Pay, {
                headers: {
                    safe2pay: infoLogin.api_safe2pay
                }
            });
            if (response && response.status === 200 && response.data) {
                if (response.data.HasError) {
                    AlertInfo('error', 'FALHA NA OPERAÇÃO', response.data.Error);
                } else {
                    const { Message, IdTransaction, Status } = response.data.ResponseDetail;
                    let link_payment = '';
                    let link_payment2 = '';
                    if (pagamento === "1") {
                        link_payment = response.data.ResponseDetail.BankSlipUrl;
                    } else if (pagamento === "6") {
                        link_payment = response.data.ResponseDetail.QrCode;
                        link_payment2 = response.data.ResponseDetail.Key;
                        setLinkQrCodePix(link_payment);
                        setPixCopiaCola(link_payment2);
                    }
                    const data = {
                        id_safe_2_pay: IdTransaction,
                        message_safe2pay: Message,
                        status_safe2pay: Status,
                        link_payment, link_payment2, pagamento, vencimento_cobranca: vencimento
                    };
                    const where = { id_certificado: info.id_certificado };
                    const responseApi = await api.post(`/updatecertificado`, { where, data });
                    if (responseApi && responseApi.status === 200 && responseApi.data) {
                        if (responseApi.data.success) {
                            AlertInfo('success', 'COBRANÇA GERADA').then(() => {
                                if (pagamento === "1") window.open(link_payment);
                            });
                        }
                    }
                }
            }
        } catch (error) {
            console.log(error);
            AlertInfo('error', 'FALHA NA CONEXÃO');
        }
        setOpenLoad(false);
    }

    function copyLink(text, msg) {
        navigator.clipboard.writeText(text);
    }

    useEffect(() => {
        if(isLink && info && info.link_payment2) {
            setLinkQrCodePix(info.link_payment);
            setPixCopiaCola(info.link_payment2);
        }
        // eslint-disable-next-line
    }, [isLink]);

    return (
        <Modal
            open={open}
            setOpen={setOpen}
            title="EMITIR COBRANÇA"
            maxWidth="xs"
        >
            <SimpleBackdrop open={openLoad} />
            <Grid container spacing={1}>
                {!linkQrCodePix && pagamento === "1" && (
                    <>
                        <Grid item xs={12}>
                            <Input
                                label="VENCIMENTO DO BOLETO"
                                value={vencimento}
                                setValue={setVencimento}
                                type="date"
                                upper={false}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Input
                                label="INSTRUÇÕES DO BOLETO"
                                value={instrucoes}
                                setValue={setInstrucoes}
                            />
                        </Grid>
                    </>
                )}
                {linkQrCodePix && (
                    <>
                        <Grid item xs={12} container justifyContent="center">
                            <h3>PAGUE COM PIX ;)</h3>
                            <img src={`${apiUrl}/qrcode?data=${linkPixCopiaCola}`} alt="Chave Pix" height={300} />
                        </Grid>
                        <Grid item xs={12}>
                            <span
                                style={{ wordWrap: 'break-word', cursor: 'pointer' }}
                                onClick={() => copyLink(linkPixCopiaCola)}
                            >
                                {linkPixCopiaCola}
                            </span>
                        </Grid>
                    </>
                )}
                {!linkQrCodePix && (
                    <Grid item xs={12}>
                        <Button
                            variant="contained"
                            fullWidth
                            onClick={emitirCobranca}
                        >
                            EMITIR COBRANÇA
                        </Button>
                    </Grid>
                )}
            </Grid>
        </Modal>
    );
}